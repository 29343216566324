.excursions {
  font-family: Arial, sans-serif;
  padding: 30px 50px;
  background: linear-gradient(135deg, #f9f9f9 0%, #fff 100%);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-height: 400px;
  margin-top: 30px;
  margin-bottom: 70px;
  transition: box-shadow 0.3s, transform 0.2s;
  text-align: start;
  width: 70%;
}

.excursions h2 {
  color: #ff6600;
  font-size: 1.8em;
  margin-bottom: 40px;
}

.excursions strong {
  font-size: 1.2em;
  color: #333;
  display: block;
  margin-bottom: 15px;
}

.excursions-list {
  list-style-type: disc;
  padding-left: 20px; /* Adjusts indentation for bullets */
  margin: 20px 20px;
  width: 80%;
  text-align: left;
}

.excursion-item {
  padding: 5px 10px;
  
  font-size: 1.1em;
  color: #555;
}

.excursion-item:last-child {
  margin-bottom: 20px;
}
/* Arabic (RTL) Version - Class Selector Only */
.excursions.rtl {
  font-family: "Tahoma", "Arial", sans-serif;
  text-align: right;
  direction: rtl;
}

.excursions.rtl h2 {
  font-family: "Tahoma", "Arial", sans-serif;
  color: #ff6600;
  margin-bottom: 40px;
}

.excursions.rtl strong {
  font-family: "Tahoma", "Arial", sans-serif;
  font-weight: 600;
}

.excursions.rtl .excursions-list {
  list-style-type: disc;
  padding-right: 20px;
  padding-left: 0;
  margin-right: 20px;
  margin-left: 0;
  text-align: right;
  width: 80%;
}

.excursions.rtl .excursion-item {
  padding: 5px 10px;
  font-size: 1.1em;
  color: #555;
  letter-spacing: 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .excursions.rtl {
    padding: 20px 30px;
    width: 85%;
  }
  
  .excursions.rtl .excursions-list {
    width: 90%;
    padding-right: 15px;
  }
}

/* Hover effects */
.excursions.rtl:hover {
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
}