/* myTickets.css */
.my-tickets {
    width: 100%;
    padding: 1rem;
    font-family: "Open Sans", sans-serif;
  }
  
  .my-tickets h2 {
    font-size: 24px;
    margin-bottom: 1.5rem;
    color: #043263;
    font-weight: 700;
  }
  
  .my-tickets table {
    width: 90%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .my-tickets th,
  .my-tickets td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .my-tickets th {
    background-color: #f5f5f5;
    font-weight: 700;
    color: #043263;
    text-transform: uppercase;
    font-size: 14px;
  }
  
  .my-tickets td {
    color: #4f4f4f;
    font-size: 14px;
  }
  
  .my-tickets tr:hover {
    background-color: #f9f9f9;
  }
  
  /* Status Styling */
  .my-tickets td:nth-child(2) {
    font-weight: 600;
  }
  
  .my-tickets td[data-status="Open"] {
    color: #ffa800; /* Yellow for Open */
  }
  
  .my-tickets td[data-status="Closed"] {
    color: #4caf50; /* Green for Closed */
  }
  
  .my-tickets td[data-status="In Progress"] {
    color: #239efe; /* Blue for In Progress */
  }
  
  /* Pagination Styles */
  .pagination {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  
  .pagination button {
    padding: 0.5rem 1rem;
    background-color: #043263;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination span {
    font-size: 14px;
    color: #4f4f4f;
  }
  
  /* Responsive Table */
  @media screen and (max-width: 768px) {
    .my-tickets table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  
    .my-tickets th,
    .my-tickets td {
      min-width: 120px;
    }
  }

  /* Status Styling */
.my-tickets td[data-status="open"] {
    color: #ffa800; /* Yellow for Open */
  }
  
  .my-tickets td[data-status="closed"] {
    color: #4caf50; /* Green for Closed */
  }