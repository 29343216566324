.Page_visa{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}

.mobileDO{
  width: 100%;
}
.mobileDO2{
  width: 95%;
}
.Page_visa .main{
    width: 90%;
    padding: 0 0;
}

.Page_visa .main .title{
    color: #292930;
    font-family: "Poppins";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 44.8px */
}


.Page_visa .main .section1{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #AEAEAE;
}


.Page_visa .main .section1 .title{
    margin-left: 1rem;
    width: 95%;
    color: #000;
    font-family: "Open Sans";
    font-size: 18.105px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.632px; 
}


.Page_visa .main .section1 .row {
    margin-left: 1rem;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.Page_visa .main .section1 .row label .input_label.z{
    z-index: 999 !important;
}
.Page_visa .main .section1 .row label .input_label{

    padding: 0 .3rem;
    background-color: #fff;
    position: absolute;
    top: -26px;
    left: 15px;


    color: #828282;
font-family: "Poppins";
font-size: 13.579px;
font-style: normal;
font-weight: 400;
line-height: 22.632px;
}



.Page_visa .main .section1 .row label {
    width: 20%;
    height: 50px;
  position: relative;
}
.Page_visa .main .section1 .row label input {
    outline: none;
    padding-left: 1rem;
  border-radius: 4.526px;
  border: 1.132px solid #b2bcca;
  background: #fff;
  width:100%;
  min-width: 50px;
  height: 45px;
}


.Page_visa .main .section1 .row label input::placeholder{
    color: #c5c5c5;
font-family: "Poppins";
font-size: 16.974px;
font-style: normal;
font-weight: 600;
line-height: 22.632px;
}


.Page_visa .main .section1 .row label .css-1hb7zxy-IndicatorsContainer{
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .Page_visa .main .section1 .row label .css-b62m3t-container{
    
    width: 100%;
  }

  .Page_visa .main .section1 .row label .biggo {
    
    outline: none;
    padding-left: 1rem;
  border-radius: 4.526px;
  border: 1px solid #b2bcca;
  background: #fff;
  width:90%;
  min-width: 50px;
  height: 50px;
  }

  .Page_visa .main .section1 .row label input.biggo::-webkit-inner-spin-button,
  .Page_visa .main .section1 .row label input.biggo::-webkit-outer-spin-button {
    -webkit-appearance: inner-spin-button !important;
    width: 30px; 
    height: 100%; 
    background: #f0f0f0; 
    border-left: 1px solid #ccc; 
    opacity: 1; 
    cursor: pointer; 
    margin: 0; /* Ensure no unexpected margins */
  }


.Page_visa .main .section1 .row .input.var2{
    width: 20%;
  }

.Page_visa .main .section1 .row .input{
    cursor: pointer;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    background: #FFF;    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
    padding: 2px;
    
  }
.Page_visa .main .section1 .row .input img{
    margin:auto .7rem;
  }
.Page_visa .main .section1 .row .input input{
    color: #B6B6B6;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 152%;
letter-spacing: 0.7px;
    width: 100%;
    height: 100%;
    border: none;
  }
.Page_visa .main .section1 .row .input input:focus{
    outline: none;
  }








  .Page_visa .main .section1 .row2 label input::placeholder{
    color: #c5c5c5;
font-family: "Poppins";
font-size: 16.974px;
font-style: normal;
font-weight: 600;
line-height: 22.632px;
}


.Page_visa .main .section1 .row2 label .css-1hb7zxy-IndicatorsContainer{
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .Page_visa .main .section1 .row2 label .css-b62m3t-container{
    width: 100%;
  }



.Page_visa .main .section1 .row2 .input.var1{
    width: 20%;

  }
  .Page_visa .main .section1 .row2 .input.var2{
    width: 46%;

  }

.Page_visa .main .section1 .row2 .input{
    
    cursor: pointer;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    background: #FFF;    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
    padding: 2px;
    
  }
.Page_visa .main .section1 .row2 .input img{
    margin:auto .7rem;
  }
.Page_visa .main .section1 .row2 .input input{
    color: #B6B6B6;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 152%;
letter-spacing: 0.7px;
    width: 100%;
    height: 100%;
    border: none;
  }
.Page_visa .main .section1 .row2 .input input:focus{
    outline: none;
  }


  .Page_visa .main .section1 .row2 {
    margin-bottom: 3rem;
    margin-top: 1.5rem;
    margin-left: 1rem;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.Page_visa .main .section1 .row2 label .input_label.z{
    z-index: 999 !important;
}
.Page_visa .main .section1 .row2 label .input_label{

    padding: 0 .3rem;
    background-color: #fff;
    position: absolute;
    top: -26px;
    left: 15px;


    color: #828282;
font-family: "Poppins";
font-size: 13.579px;
font-style: normal;
font-weight: 400;
line-height: 22.632px;
}



.Page_visa .main .section1 .row2 label {
    width: 46.66%;
    height: 50px;
  position: relative;
}
.Page_visa .main .section1 .row2 label input {
    outline: none;
    padding-left: 1rem;
  border-radius: 4.526px;
  border: 1.132px solid #b2bcca;
  background: #fff;
  width:100%;
  min-width: 50px;
  height: 50px;
}

.Page_visa .main .section1 .biggy  {
  width: 95%;
}

.Page_visa .main .section1 .biggy .two_inputs label .input_label.z{
  z-index: 999 !important;
}
.Page_visa .main .section1 .biggy .two_inputs label .input_label{

  padding: 0 .3rem;
  background-color: #fff;
  position: absolute;
  top: -26px;
  left: 15px;


  color: #828282;
font-family: "Poppins";
font-size: 13.579px;
font-style: normal;
font-weight: 400;
line-height: 22.632px;
}

.Page_visa .main .section1 .biggy .two_inputs {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
width: 100%;
}

.Page_visa .main .section1 .biggy .two_inputs label {
  width: 45%;
  height: 50px;
position: relative;
}
.Page_visa .main .section1 .biggy .two_inputs label input {
  outline: none;
  padding-left: 1rem;
border-radius: 4.526px;
border: 1px solid #b2bcca;
background: #fff;
width:90%;
min-width: 50px;
height: 50px;
}


.Page_visa .main .section1 .biggy .two_inputs label input::placeholder{
  color: #c5c5c5;
font-family: "Poppins";
font-size: 16.974px;
font-style: normal;
font-weight: 600;
line-height: 22.632px;
}










.Page_visa .main .section1 .biggy .two_inputs .input.var3{
  margin-right: 3.2%;
  width: 41.5%;

}

.Page_visa .main .section1 .biggy .two_inputs .input.var3{
  
  cursor: pointer;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #b2bcca;
  background: #FFF;    
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  padding: 2px;
  
}
.Page_visa .main .section1 .biggy .two_inputs .input.var3 img{
  margin:auto .7rem;
}
.Page_visa .main .section1 .biggy .two_inputs .input.var3 input{
  color: #B6B6B6;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 152%;
letter-spacing: 0.7px;
  width: 100%;
  height: 100%;
  border: none;
}
.Page_visa .main .section1 .biggy .two_inputs .input.var3 input:focus{
  outline: none;
}




.Page_visa .main .section1 .documents{

  width: 95%;
  margin: 1rem 0;
  margin-bottom: 3rem;
  background: rgba(35, 158, 254, 0.07);
  padding: 10px 0px;
}


.Page_visa .main .section1 .documents .container{
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.Page_visa .main .section1 .documents .container .right_part{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.Page_visa .main .section1 .documents .container .right_part .el{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.Page_visa .main .section1 .documents .container .right_part .el .text{
  color: #363636;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
  width: 30%;
  margin: 0;
  padding: 0;
}

.Page_visa .main .section1 .documents .container .right_part .el button{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.79px;
border: 0.5px solid #6B6B6B;
background: #E0E0E0;
min-width: 50px;
height: 30px;
  width: 20%;
}

.Page_visa .main .section1 .documents .container .right_part .el .file_name{
  overflow: hidden;
  text-overflow: ellipsis;
  color: green;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
  margin-left: 2rem;
  width: 40%;
}
.Page_visa .main .section1 .documents .container .right_part .el .file_name .not_selected{
  color: #909090;
font-family: "Poppins";
font-size: 13px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-transform: capitalize;
}


.Page_visa .main .section1 .documents .container .left_part{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Page_visa .main .section1 .documents .container .left_part .text{
  background: rgba(35, 158, 254, 0.07);
  padding: 1rem;
  width: 80%;
}

.Page_visa .main .section1 .documents .container .left_part .text p{
  color: #239EFE;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
}


.Page_visa .main .section1 .note{
  background: rgba(35, 158, 254, 0.07);
  padding: .7rem;
}

.Page_visa .main .section1 .note p{
  color: #239EFE;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.Page_visa .main .section1 .note p .bluer{
  margin-right: auto;
  color: #239EFE;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.Page_visa .main .section1 .full_width{
  width: 95%;
  margin-left: 1rem;
  margin-top: 1rem;
}

.Page_visa .main .section1 .conditions{
width: 95%;
margin-left: 1rem;


}

.Page_visa .main .section1 .conditions p{
  color: #363636;
  text-align: justify;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 245.5%; /* 39.28px */
  text-transform: capitalize;
}

.Page_visa .main .section1 .check{
  width: 95%;
  margin: 1.5rem 0;

margin-left: 1rem;}

.Page_visa .main .section1 .check label{
  cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.Page_visa .main .section1 .check label .text{
  margin-left: .5rem;
    color: #4F4F4F;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

}
.Page_visa .main .section1 .check label input{
  width: 13px;
  height: 13px;
  cursor: pointer;
}
.Page_visa .main .section1 .check label .text a{
    text-decoration: none;
    border: none;
    color: #239EFE;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

}

.Page_visa .main .section1 .price_confirm{
  margin:0 2rem 2rem 0;
  width: 95%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.Page_visa .main .section1 .price_confirm p{
  margin-right: 2rem;
  color: #000;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 22.632px; /* 113.158% */
}

.Page_visa .main .section1 .price_confirm button{
  cursor: pointer;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  border-radius: 4px;
background: #FF6300;
border: none;
}


.Page_visa .main .section1 .price_confirm button .button{
  color: #FAFAFA;
text-align: center;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
margin: 0;
padding: 0%;
}

.loader-container {
  display: flex;
  justify-content: center;  /* Centers horizontally */
  align-items: center;      /* Centers vertically */
  height: 100vh;            /* Full viewport height */
}




@media (max-width: 768px) {
  .Page_visa .main {
    width: 90%;
    padding: 0 0.5rem;
  }

.Page_visa .main .section1 .conditions {
  width: 90%;
  margin-left: 1rem;
}

.mobileDO{
  width: 90%;
}
.mobileDO2{
  width: 85%;
}

    .Page_visa .main .section1 .conditions p {
      color: #363636;
      text-align: justify;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 148.5%;
      text-transform: capitalize;
    }

  .Page_visa .main .title {
    color: #292930;
    font-family: "Poppins";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 44.8px */
    margin-bottom: 40px;
  }


  .Page_visa .main .section1 .row2 .input.var2{
    width: 98%;

  }

  .Page_visa .main .section1 .row,
  .Page_visa .main .section1 .row2 {
    flex-direction: column;
  }

  .Page_visa .main .section1 .row label,
  .Page_visa .main .section1 .row2 label {
    width: 100%;
    margin-bottom: 40px;
  }

  .Page_visa .main .section1 .biggy .two_inputs {
    flex-direction: column;
    align-items: flex-start;
  }

  .Page_visa .main .section1 .biggy .two_inputs label,
  .Page_visa .main .section1 .biggy .two_inputs .input.var3 {
    width: 100%;
    margin-bottom: 1rem;
  }

  .Page_visa .main .section1 .biggy .two_inputs label input,
  .Page_visa .main .section1 .biggy .two_inputs .input.var3 input {
    width: 98%;
  }

  .Page_visa .main .section1 .row label .biggo {
    

  width:96%;

  }

  .Page_visa .main .section1 .price_confirm {
    flex-direction: column;
    align-items: flex-start;
    margin: 0rem 0rem 1rem 0rem;
  }

  .Page_visa .main .section1 .price_confirm p {
    margin-right: 1rem;
    font-size: 16px;
  }

  .Page_visa .main .section1 .price_confirm button {
    padding: 0.5rem 1rem;
    font-size: 12px;
  }

  .Page_visa .main .section1 .row .input.var2 {
    width: 100%;
    margin-bottom: 40px;
  }

  .Page_visa .main .section1 .row label .input_label.z,
  .Page_visa .main .section1 .row2 label .input_label.z {
    z-index: -1 !important;
  }

  .Page_visa .main .section1 .row label .input_label,
  .Page_visa .main .section1 .row2 label .input_label {
    top: -40px;
    left: 0px;
  }

  .Page_visa .main .section1 .documents .container .right_part,
  .Page_visa .main .section1 .documents .container .left_part {
    width: 100%;
  }

  .Page_visa .main .section1 .documents .container .right_part .el .file_name {
    margin-left: 1rem;
  }

  .Page_visa .main .section1 .documents .container .right_part .el .file_name,
  .Page_visa .main .section1 .documents .container .right_part .el .text {
    font-size: 14px;
  }

  .Page_visa .main .section1 .documents .container{
    flex-direction: column;
    gap: 40px;
    margin-left: 0px;
  }

  .Page_visa .main .section1 .documents .container .left_part{
    justify-content: center;
  }

  .Page_visa .main .section1 .documents .container .right_part .el{
    flex-direction: column;
    
  }

  .Page_visa .main .section1 .documents .container .right_part .el .text{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    
  }
  .Page_visa .main .section1 .documents .container .right_part .el button{
 
    width: 30%;
  }

  .Page_visa .main .section1 .documents .container .right_part .el button p{
 
    width: 100%;
  }


  .Page_visa .main .section1 .documents .container .right_part{
    gap: 20px;
  }

  .Page_visa .main .section1 .documents .container .right_part .el .file_name{
    width: 100%;
    text-align: center;
  }
}



@media (max-width: 563px) {
  .Page_visa .main .title {
    font-size: 24px;
    margin-bottom: 28px;
  }

  .Page_visa .main .section1 .biggy .two_inputs {
    gap: 15px;
  }


  .mobileDO{
    width: 88%;
  }

  .mobileDO2{
    width: 80%;
  }

  .Page_visa .main .section1 .conditions {
    
    margin-left: 0rem;
  }

  .Page_visa .main {
    width: 95%;
    padding: 0 0.25rem;
  }

  .Page_visa .main .section1 .conditions p {
    color: #363636;
    text-align: justify;
    font-family: "Poppins";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 148.5%;
    text-transform: capitalize;
  }

  .Page_visa .main .section1 .biggy .two_inputs label input,
  .Page_visa .main .section1 .biggy .two_inputs .input.var3 input {
    width: 96%;
  }

  .Page_visa .main .section1 .row label .biggo {
    width: 94%;
  }

  .Page_visa .main .section1 .row label,
  .Page_visa .main .section1 .row2 label {
    margin-bottom: 40px;
  }

  .Page_visa .main .section1 .price_confirm p {
    font-size: 14px;
  }
  
  .Page_visa .main .section1 .price_confirm button {
    padding: 0.4rem 0.8rem;
    font-size: 11px;
  }

  .Page_visa .main .section1 .row .input.var2 {
    width: 98%;
    margin-bottom: 40px;
  }
}

/* RTL specific styles */
[dir="rtl"] .two_inputs {
  flex-direction: row-reverse;
}

[dir="rtl"] .input_label {
  text-align: right;
}

[dir="rtl"] .conditions p,
[dir="rtl"] .note p,
[dir="rtl"] .check label,
[dir="rtl"] .text {
  text-align: right;
}

[dir="rtl"] .el button {
  margin-right: 20px;
  margin-left: 0;
}

[dir="rtl"] .price_confirm {
  flex-direction: row-reverse;
}

/* Adjust Select components for RTL */
[dir="rtl"] .css-1s2u09g-control,
[dir="rtl"] .css-1pahdxg-control {
  text-align: right;
}

[dir="rtl"] .css-1okebmr-indicatorSeparator {
  margin-right: 8px;
  margin-left: 0;
}


.Page_visa[dir="rtl"] .main .section1 .biggy .two_inputs label .input_label {
  right: 15px; /* Use right instead of left for RTL */
  left: auto; /* Reset left positioning */
}


.Page_visa[dir="rtl"] .main .section1 .row label .input_label{
  right: 15px; /* Use right instead of left for RTL */
  left: auto; /* Reset left positioning */
}


.Page_visa[dir="rtl"] .main .section1 .row2 label .input_label{
  right: 15px; /* Use right instead of left for RTL */
  left: auto; /* Reset left positioning */
}


.Page_visa[dir="rtl"] .main .section1 .biggy .two_inputs .input.var3 {
  margin-right: 0;
  margin-left: 3.2%; /* Flip the margin for RTL */
  width: 41.5%;
  
  /* Additional RTL-specific adjustments */
  direction: rtl;
  text-align: right;
  padding-right: 0rem;
  padding-left: 0;
}


.Page_visa[dir="rtl"] .main .section1 .documents{
  padding-right: 20px;
}