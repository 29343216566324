.Hotelroom {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    width: 30%;
    max-width: 470px;
    min-width: 300px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    margin: 1rem 0;
    overflow: hidden;
    transition: all 0.3s ease;
    background-color: #fff;
}

.Hotelroom:hover {
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
}

.Hotelroom .top_image_container {
    width: 100%;
    height: 220px;
    overflow: hidden;
    position: relative;
}

.Hotelroom .top_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.Hotelroom .bottom_container {
    padding: 1.5rem;
    width: 100%;
    box-sizing: border-box;
}

.Hotelroom .bottom_container .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
}

.Hotelroom .bottom_container .info .icon {
    margin-right: 1rem;
    width: 24px;
    height: 24px;
}

.Hotelroom .bottom_container .info .el {
    color: #333;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.Hotelroom .bottom_container .desc {
    color: #666;
    text-align: justify;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.Hotelroom .bottom_container .reservation {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Hotelroom .bottom_container .reservation .price {
    color: #333;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.Hotelroom .bottom_container .reservation button {
    cursor: pointer;
    padding: 12px 24px;
    border-radius: 8px;
    border: none;
    background: #FF6300;
    transition: all 0.3s ease;
}

.Hotelroom .bottom_container .reservation button:hover {
    background: #FF7F23;
    box-shadow: 0 5px 15px rgba(255, 99, 0, 0.3);
}

.Hotelroom .bottom_container .reservation button p {
    margin: 0;
    color: #FFF;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
}