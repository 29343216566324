.Pages_vols {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.Pages_vols .main_top {
  margin-top: 2rem;
  width: 90%;
}

.Pages_vols .main_top .header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
}
.Pages_vols .main_top .header .el {
  cursor: pointer;
  margin: 0 2rem;
  color: #686868;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Pages_vols .main_top .header .el:hover {
  color: #043263;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 4px solid #043263;
  transition: all 50ms ease;
}
.Pages_vols .main_top .header .el.selected {
  color: #043263;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 4px solid #043263;
  transition: all 50ms ease;
}

.Pages_vols .main_top .fields {
  margin-top: 2rem;
}

.Pages_vols .main_top .fields {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.Pages_vols .main_top .fields .row {
  width: 100%;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}


.Pages_vols .main_top .fields .row .input1 {
  cursor: pointer;
  width: 450px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  opacity: 0.95;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  background: #fff;
  position: relative;
}

.Pages_vols .main_top .fields .row .input1 .personnes_input {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  opacity: 0.95;
}
.Pages_vols .main_top .fields .row .input1 .personnes_input:hover {
  opacity: 1;
}
.Pages_vols .main_top .fields .row .input1 .personnes_input .icon {
  margin: auto 0.7rem;
}
.Pages_vols .main_top .fields .row .input1 .personnes_input .click_icon {
  margin: auto 0.7rem auto auto;
}

.Pages_vols .main_top .fields .row .input1 .personnes_input .placeholder {
  color: #b6b6b6;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 152%;
  letter-spacing: 0.7px;
}

.Pages_vols .main_top .fields .row .input1 .sliding-div {
  position: absolute;
  bottom: -150px;
  overflow: hidden;
  height: 150px;
  width: 100%;
  display: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 999;
  background-color: white;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100px);
  }
}

.Pages_vols .main_top .fields .row .input1 .sliding-div.expanded {
  display: flex;
}


.Pages_vols .main_top .fields .row .input1 .sliding-div button {
  margin-bottom: 10px;
}

.Pages_vols .main_top .fields .row .input1 .sliding-div .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  cursor: default;
  /* Additional styling for the content */
  width: 100%;
  height: 100%;
}

.Pages_vols .main_top .fields .row .input1 .sliding-div .content .row {
    border: none;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
}
.Pages_vols .main_top .fields .row .input1 .sliding-div .content .row .icon {
  margin: auto 0.7rem;
}
.Pages_vols .main_top .fields .row .input1 .sliding-div .content .row .title {
  color: #2b2a2a;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 152%;
  letter-spacing: 0.7px;
}
.Pages_vols
  .main_top
  .fields
  .row
  .input1
  .sliding-div
  .content
  .row
  .faded_text {
  color: #a6a6a6;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 152%;
  letter-spacing: 0.7px;
  margin-left: 0.5rem;
}
.Pages_vols
  .main_top
  .fields
  .row
  .input1
  .sliding-div
  .content
  .row
  .perssoneshandler {
  margin: auto 0.7rem auto auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.Pages_vols
  .main_top
  .fields
  .row
  .input1
  .sliding-div
  .content
  .row
  .perssoneshandler
  img:hover {
  opacity: 1;
}
.Pages_vols
  .main_top
  .fields
  .row
  .input1
  .sliding-div
  .content
  .row
  .perssoneshandler
  .disabled:hover {
  opacity: 0.92;
}
.Pages_vols
  .main_top
  .fields
  .row
  .input1
  .sliding-div
  .content
  .row
  .perssoneshandler
  img {
  opacity: 0.92;
  cursor: pointer;
  margin: auto 0.4rem;
}

.Pages_vols
  .main_top
  .fields
  .row
  .input1
  .sliding-div
  .content
  .row
  .perssoneshandler
  .disabled {
  filter: invert(93%) sepia(0%) saturate(0%) hue-rotate(142deg) brightness(85%)
    contrast(83%);
}

.Pages_vols .main_top .fields .row .input.var1 {
  width: 450px;
}

.Pages_vols .main_top .fields .row .input {
  cursor: pointer;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  padding: 2px;
}
.Pages_vols .main_top .fields .row .input img {
  margin: auto 0.7rem;
}
.Pages_vols .main_top .fields .row .input input {
  color: #b6b6b6;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 152%;
  letter-spacing: 0.7px;
  width: 100%;
  height: 100%;
  border: none;
}
.Pages_vols .main_top .fields .row .input input:focus {
  outline: none;
}

.Pages_vols .main_top .fields .row .check_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    width: 450px;
}
.Pages_vols .main_top .fields .row .check_container label{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.Pages_vols .main_top .fields .row .check_container label input{
    margin-right: .5rem;
    cursor: pointer;
    appearance: none;
    width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #E0E1E4;
  padding: 2px;
}

.Pages_vols .main_top .fields .row .check_container label input:checked{
    border: none;
    background-image: url("../../assets/images/icons/checkbox.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
}

.Pages_vols .main_top .fields .row .check_container label p{
    color: #808080;

/* Body/Regular/14 */
font-family: "Source Sans 3";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.Pages_vols .main_top .fields .row .check_container label .selected{
    color: #239EFE;
    font-family: "Source Sans 3";
/* font-family: "Myriad Pro"; */
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}



.Pages_vols .main_top .fields .row .rechercher{
    border: none;
    border-radius: 4px;
    background: #043263;
    width: 450px;
    cursor: pointer;

}



.Pages_vols .main_top .fields .row .rechercher p{
    color: #FAFAFA;
text-align: center;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
}






.Pages_vols .p2{
    background-color: #F8FCFF;
    margin-top: 3rem;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 5rem;
}

.Pages_vols .p2 .left_part{
    padding-bottom: 5rem;
    background-color: #fff;
    margin: 2rem 1rem  0 2rem ;
    padding-left: 1rem;
    min-height: 1500px;
    min-width: 250px;
    width: 20%;
}

.Pages_vols .p2 .left_part .title{
    margin: .5rem .5rem .5rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.Pages_vols .p2 .left_part .title p{
    color: #8A8A8A;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 155.5%;
letter-spacing: 1.04px;
text-transform: uppercase;
}

.Pages_vols .p2 .left_part .label{
    margin-bottom: 3rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.Pages_vols .p2 .left_part .label p{
    color: #5E5E5E;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 152%;
letter-spacing: 0.7px;
}

.Pages_vols .p2 .left_part .label input{
    padding-left: 1rem;
    width: 250px;
height: 48px;
flex-shrink: 0;
    border-radius: 4px;
border: 1px solid #DBDBDB;
background: #FFF;
}

.Pages_vols .p2 .left_part .label input::placeholder{
    color: #201F1F;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.7px;
}

.Pages_vols .p2 .left_part .label .range{
    border-radius: 4px;
border: 0.374px solid #9E9E9E;
background: #FFF;
width: 270px;
height: 35px;
flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Pages_vols .p2 .left_part .label .range p{
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 152%;
letter-spacing: 0.7px;
}

.Pages_vols .p2 .left_part .label .range p .golden_tag{
    margin: 0 2rem 0 0rem;
   color: #FF6300;
}
.Pages_vols .p2 .left_part .label .range p .blue_tag{
    margin: 0 2rem 0 2rem;

     color: #239EFE;
}
.Pages_vols .p2 .left_part .label .range p .dark_blue_tag{
    color: #043263;
font-family: "Poppins";
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 152%;
letter-spacing: 0.6px;
}




.Pages_vols .p2 .left_part .label label{
    display: flex;
    align-items: center;
    width: 90%;
}
.Pages_vols .p2 .left_part .label label input{
    margin-right: .5rem;
    cursor: pointer;
    appearance: none;
    width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #E0E1E4;
  padding: 2px;
}

.Pages_vols .p2 .left_part .label label input:checked{
    border: none;
    background-image: url("../../assets/images/icons/checkbox.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
}

.Pages_vols .p2 .left_part .label label p{
    color: #808080;

/* Body/Regular/14 */
font-family: "Source Sans 3";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.Pages_vols .p2 .left_part .label label .selected{
    color: #239EFE;
    font-family: "Source Sans 3";
/* font-family: "Myriad Pro"; */
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}
.Pages_vols .p2 .right_part{
  padding: 2rem 0;
  padding-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    min-width: 900px;
    width: 75%;
    min-height: 1500px;
    margin-top: 2rem;
}

.Pages_vols .p2 .right_part .title{
    width: 90%;
    font-family: "Poppins";
font-size: 24px;
font-weight: 700;
line-height: 34px;
letter-spacing: 0em;
text-align: left;
color: #292930;
}

.Pages_vols .p2 .right_part button p{
    font-family: "Poppins";
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: center;
color: #fff;
}
.Pages_vols .p2 .right_part .button{
    margin-top: 3rem;
    margin-bottom: 4.9rem;
    border-radius: 4px ;
    border: none;
    padding: .3rem 4rem;
    background-color: #043263;
    
}


.Pages_vols .p2 .right_part .container_80{
  min-height: 1500px;

 
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.Pages_vols .p2 .right_part .container_80 .top_info{
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.Pages_vols .p2 .right_part .container_80 .top_info .vol{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.Pages_vols .p2 .right_part .container_80 .top_info .vol .city{
  color: #292930;
font-family: "Poppins";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 140%;
}



.Pages_vols .p2 .right_part .container_80 .top_info .vol img{
  margin: auto 1rem;
}

.Pages_vols .p2 .right_part .container_80 .top_info .details{
  margin: auto 2rem;
  color: #686868;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
}

.Pages_vols .p2 .right_part .container_80 .top_info button{
  padding: 0 2rem;
  margin: auto 0 auto auto;
  border-radius: 3.722px;
border: 1px solid #868686;
background-color: #fff;
cursor: pointer;
height: 35px;
}


.Pages_vols .p2 .right_part .container_80 .top_info button p{
  margin: auto;
  color: #4B4B4B;
text-align: center;
font-family: "Poppins";
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18.612px;
}






.Pages_vols .p2 .right_part .container_80 .list{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

}






.Pages_vols .p2 .right_part  .container_80 .list .el{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 1rem 0;
  width: 100%;
}




















/* inputs width controllers */

@media only screen and (max-width: 1400px) {
  .Pages_vols .main_top .fields .row .input1 {
    width: 350px;
  }
  .Pages_vols .main_top .fields .row .input.var1 {
    width: 346px;
  }
  .Pages_vols .main_top .fields .row .check_container{
    width: 350px;
  }
  .Pages_vols .main_top .fields .row .rechercher{
    width: 350px;
  }
}

@media only screen and (max-width: 1100px) {
  .Pages_vols .main_top .fields .row .input1 {
    width: 250px;
  }
  .Pages_vols .main_top .fields .row .input.var1 {
    width: 246px;
  }
  .Pages_vols .main_top .fields .row .check_container{
    width: 250px;
  }
  .Pages_vols .main_top .fields .row .rechercher{
    width: 250px;
  }
}
@media only screen and (min-width: 1400px) {
    .Pages_vols .main_top .fields .row .input1 {
      width: 450px;
    }
    .Pages_vols .main_top .fields .row .input.var1 {
      width: 446px;
    }
    .Pages_vols .main_top .fields .row .check_container{
      width:450px;
    }
    .Pages_vols .main_top .fields .row .rechercher{
      width: 450px;
    }
  
  }
@media only screen and (min-width: 1600px) {
  .Pages_vols .main_top .fields .row .input1 {
    width: 500px;
  }
  .Pages_vols .main_top .fields .row .input.var1 {
    width: 496px;
  }
  .Pages_vols .main_top .fields .row .check_container{
    width:500px;
  }
  .Pages_vols .main_top .fields .row .rechercher{
    width: 500px;
  }

}




