.Page_hotels {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}





.Page_hotels .p2{
    background-color: #F8FCFF;
 
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 5rem;
}

.Page_hotels .p2 .left_part .kiko .label select{
  width: 250px;
  
  
}

.Page_hotels .p2 .left_part{
    padding-bottom: 5rem;
    background-color: #043263;

    margin: 0rem 1rem  0 2rem ;
    padding-left: 1.5rem;
   position: sticky;
   left: 0px;
   top: 0px;
   margin: 2rem 1rem;
    min-height: 800px;
    min-width: 250px;
    width: 20%;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    background-image: url("../../assets/images/images/ville.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position:top;

    z-index: 1;
    
}

.Page_hotels .p2 .left_part .title{
    margin: .5rem .5rem .5rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    color: rgba(255, 123, 0, 0.884);
}

.Page_hotels .p2 .left_part .title p{

font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 155.5%;
letter-spacing: 1.04px;
text-transform: uppercase;
color: rgba(255, 123, 0, 0.884);
}

.Page_hotels .p2 .left_part .label{
   
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.Page_hotels .p2 .left_part .label p{
  color: white;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 152%;
letter-spacing: 0.7px;
}

.Page_hotels .p2 .left_part .label input{
    padding-left: 1rem;
    width: 250px;
height: 48px;
flex-shrink: 0;
    border-radius: 4px;
border: 1px solid #DBDBDB;
background: #FFF;
}

.Page_hotels .p2 .left_part .label input::placeholder{
    color: #201F1F;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.7px;
}

.Page_hotels .p2 .left_part .label .range{
    border-radius: 4px;
border: 0.374px solid #9E9E9E;
background: #FFF;
width: 270px;
height: 35px;
flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}
.Page_hotels .p2 .left_part .label .range p{
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 152%;
letter-spacing: 0.7px;
}

.Page_hotels .p2 .left_part .label .range p .golden_tag{
    margin: 0 2rem 0 0rem;
   color: #FF6300;
}
.Page_hotels .p2 .left_part .label .range p .blue_tag{
    margin: 0 2rem 0 2rem;

     color: #239EFE;
}
.Page_hotels .p2 .left_part .label .range p .dark_blue_tag{
    color: #043263;
font-family: "Poppins";
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 152%;
letter-spacing: 0.6px;
}




.Page_hotels .p2 .left_part .label label{
    display: flex;
    align-items: center;
    width: 90%;
}
.Page_hotels .p2 .left_part .label label input{
    margin-right: .5rem;
    cursor: pointer;
    appearance: none;
    width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #E0E1E4;
  padding: 2px;
}

.Page_hotels .p2 .left_part .label label input:checked{
    border: none;
    background-image: url("../../assets/images/icons/checkbox.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
}

.Page_hotels .p2 .left_part .label label p{
    color: #808080;

/* Body/Regular/14 */
font-family: "Source Sans 3";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.Page_hotels .p2 .left_part .label label .selected{
    color: #239EFE;
    font-family: "Source Sans 3";
/* font-family: "Myriad Pro"; */
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}
.Page_hotels .p2 .right_part{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
   
    width: 75%;
    margin-top: 2rem;
}

.Page_hotels .p2 .right_part .title{
    width: 90%;
    font-family: "Poppins";
font-size: 24px;
font-weight: 700;
line-height: 34px;
letter-spacing: 0em;
text-align: left;
color: #292930;
}

.Page_hotels .p2 .right_part button p{
    font-family: "Poppins";
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: center;
color: #fff;
}
.Page_hotels .p2 .right_part .button{
    margin-top: 3rem;
    margin-bottom: 4.9rem;
    border-radius: 4px ;
    border: none;
    padding: .3rem 4rem;
    background-color: #043263;
    
}
  


@media (min-width: 768px) {

  
  .Page_hotels .p2 {
    flex-direction: row;
  }

  .Page_hotels .p2 .left_part {
    width: 30%;
  }
  
  .Page_hotels .p2 .right_part {
    width: 70%;
  }


}



/* Desktop Breakpoint */
@media (min-width: 1024px) {

  
  .Page_hotels .p2 .left_part {
    margin-left: 0px;
    margin-right: 0px;
  }


}

/* Large Desktop Breakpoint */
@media (min-width: 1400px) {


  .Page_hotels .p2 .left_part {
    width: 20%;
  }

  .Page_hotels .p2 .right_part {
    width: 75%;
  }
}



@media (max-width: 1265px) {
  .Page_hotels .p2{
    flex-direction: column;
    width: 100%;
  }

  .Page_hotels .p2 .left_part {
    
    margin: 0rem 1rem;
  }

  .Page_hotels .p2 .left_part .kiko .label input{
    max-width: 300px;
  }


  .Page_hotels .p2 .left_part {
    width: 98%;
    height: 120px;
    
    min-height: 0px;
  }

  .Page_hotels .p2 .right_part {
    width: 100%;
    
    
    min-height: 0px;
  }

  .Page_hotels .p2 .left_part .kiko{
    
    display: flex;
    width: 100%;

    margin-bottom: 20px;
    
  }

  .Page_hotels .p2 .left_part {
    margin-left: 0px;
    margin-right: 0px;
  }

  .Page_hotels .p2 .left_part .title {
    margin: 0px;
  }

  .Page_hotels .p2 .left_part .label p {
    line-height: 25%;
  }
}


@media (max-width: 1021px) {
  .Page_hotels .p2 .left_part {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;   /* Center elements horizontally */
    justify-content: center;
    height: auto; /* Adjust height based on content */
    padding: 0px; /* Add padding for better spacing */
    width: 100%;
    position: unset;
  }

  
  .Page_hotels .p2 .left_part .kiko {
    width: 100%; /* Ensure the container takes full width */
    flex-direction: column; /* Stack child elements */
    gap: 7px; /* Add spacing between elements */
  }

  .Page_hotels .p2 .left_part .kiko .label {
    width: 100%; /* Allow labels to stretch for better alignment */
    text-align: center; 
    align-items: center;
  }






  .css-1owuse8-control{
    width: 80%;
  }

  .Page_hotels .p2 .left_part .kiko .label input,
  .Page_hotels .p2 .left_part .kiko .label select,
  .Page_hotels .p2 .left_part .kiko .label .MuiBox-root {
    width: 50%; /* Input and select fields take full width */
   padding: 0px 0px 0px 10px;
  }



  .Page_hotels .p2 .left_part {
    margin-left: 0px;
    margin-right: 0px;
  }
}

/* Input Styles */
.Page_hotels input,
.Page_hotels select,
.Page_hotels button {
  font-family: "Poppins", sans-serif;
  border-radius: 4px;
  border: 1px solid #DBDBDB;
  padding: 0.5rem;
  font-size: 14px;
}

/* Accessibility Improvements */
@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

/* High Contrast Mode Support */
@media (prefers-contrast: high) {
  .Page_hotels .p4 button,
  .Page_hotels .p2 .right_part .button {
    background: #000;
    color: #fff;
    border: 2px solid #fff;
  }
}

@media (max-width : 700px){
  .Page_hotels .p2 .left_part .kiko .label input {
    width: 80%;
  }
}