.horizontal-hotel-card {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1200px;
    margin: 1rem auto;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}



.card-img {
    width: 40%;
   
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
}

.card-img:hover {
    opacity: 0.9;
}

.card-info {
    padding: 1.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
}

.card-location {
    font-size: 0.75rem;
    color: #777;
    margin-bottom: 1rem;
}

.card-description {
    font-size: 0.95rem;
    color: #555;
    line-height: 1.5;
    margin-bottom: 1rem;
    height: 90px;
    overflow: hidden;
    width: 100%;

    word-break: break-word; 
word-break: break-all; 
overflow-wrap: break-word; 
    
}

.card-features {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.card-features h4 {
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.5rem;
    width: 100%;
}

.feature {
    padding: 0.3rem 0.6rem;
    border: 1px solid #007bff;
    border-radius: 5px;
    font-size: 0.85rem;
    color: #007bff;
    background: #f0f8ff;
}

.card-rating {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.card-rating img {
    width: 24px;
    height: 24px;
}

.rating-value {
    margin-left: 0.5rem;
    font-size: 1rem;
    color: #f4c542;
    font-weight: bold;
}

.card-recommendation {
    font-size: 0.95rem;
    color: #ff6300;
}

.recommendation-tag {
    padding: 0.3rem 0.6rem;
    background: #ffebcd;
    border-radius: 5px;
    font-weight: bold;
}

.card-reservation {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
}

.price {
    font-size: 1.2rem;
    color: #239efe;
    font-weight: bold;
    margin-bottom: 1rem;
}

.price-value {
    font-size: 1.5rem;
    color: #239efe;
}

.price-unit {
    font-size: 1rem;
    color: #666;
}

.reserve-button {
    padding: 0.8rem 1.5rem;
    background: #ff6300;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.reserve-button:hover {
    background: #e55b00;
}


@media (max-width: 1024px) {
    .horizontal-hotel-card {
        flex-direction: column;
        width: 80%;
    }

    .card-img {
        width: 100%;
        height: 200px;
    }

    .card-info {
        padding: 1rem;
        width: 90%;
    }

    .card-reservation {
        padding: 1rem;
    }

    .price-value {
        font-size: 1.3rem;
    }

    .card-description {

        width: 100%;
        
    }
}

@media (max-width: 768px) {
    .horizontal-hotel-card {
        flex-direction: column;
        width: 90%;
    }

    .card-img {
        width: 100%;
    }

    .card-info{
        width: 90%;
    }


    .card-reservation {
        width: 100%;
        padding: 1rem;
    }

    .price-value {
        font-size: 1.2rem;
    }

}


/* Small mobile screens (max-width: 480px) */
@media (max-width: 480px) {
    .Horizontalhotelcard {
        flex-direction: column;
        width: 100%;
    }

    .card-description {
        font-size: 0.95rem;
        color: #555;
        line-height: 1.5;
        
        height: 70px;
        overflow: hidden;
       
    }

    .card-features h4{
        margin-top: 0px;
    }
    .card-title{
        margin-top: 0px;
    }

    .card-img {
        width: 100%;
        height: 200px;
    }

    .Horizontalhotelcard .img {
        min-height: 150px;
    }

    .Horizontalhotelcard .informations {
        padding: 0.5rem;
        margin-left: 0;
    }

    .Horizontalhotelcard .informations .title {
        font-size: 14px;
    }

    .Horizontalhotelcard .reservation .blue_tag {
        font-size: 16px;
    }

    .Horizontalhotelcard .reservation button {
        width: 90%;
    }

    .card-reservation {
        padding-top: 0px;
    }
}

@media (max-width: 1265px) {
    .card-img:hover {
        opacity: 1;
    }
}