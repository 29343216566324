/* Main Section Styling */
.tarif-section {
  font-family: Arial, sans-serif;
  padding: 20px 40px;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 30px;
  margin-bottom: 70px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tarif-section h2 {
  color: #ff6600;
  font-size: 1.8em;
  margin-bottom: 10px;
  text-align: center;
}

.tarif-subtitle {
  font-size: 1em;
  color: #666;
  text-align: center;
  margin-bottom: 20px;
}

/* Filter Styling */
.filters {
  display: flex;
  gap: 80px;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
  
  padding: 15px 0px 15px 15px;
}

.filters label {
  font-size: 1em;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  width: 25%;
}

.filters select,
.filters input {
  margin-top: 8px;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: #f4f4f4;
  width: 100%;
  transition: border-color 0.3s;
}

.filters select:focus,
.filters input:focus {
  border-color: #ff6600;
  outline: none;
  background-color: #fff;
}

/* Departures Header Styling */
.departures-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 10px 0;
  border-bottom: 2px solid #ddd;
  color: #333;
  text-align: center;
}

.departures-header span {
  flex: 1;
}

/* Tariff List Styling */
.tarif-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 50px 0;
}

.tarif-item {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  width: 100%;

}

.departure-info {
  display: flex;
  
  align-items: center;
  justify-content: space-around;
  
}

.departure-info span {
  
  text-align: center;
  font-size: 1em;
  color: #333;
}

.tarif-item p {
  font-size: 1em;
  color: #333;
  margin: 5px 0;
}

/* Reserve Button Styling */
.reserve-button {
  background-color: #ff6600;
  color: #fff;
  border: none;
  padding: 8px 15px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 10px;
}

.reserve-button:hover {
  background-color: #e65c00;
  transform: translateY(-2px);
}

.reserve-button:active {
  background-color: #cc5200;
}

/* Tariff Details Styling */
.tarif-details {
  font-size: 1em;
  color: #333;
  margin-top: 20px;
  padding-left: 0;
  margin-left: 50px;
}

.tarif-details li {
  margin-bottom: 10px; 
}

.places-list {
  list-style-type: disc;
  margin-left: 20px;
  font-size: 0.95em;
  color: #333;
}


@media (max-width: 750px) {

  .filters {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
    
    padding: 15px 0px 15px 15px;
  }

  .filters label {
    font-size: 1em;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    width: 80%;
  }

  .filters input {
    margin-top: 8px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 2px;
    background-color: #f4f4f4;
    width: 95%;
    transition: border-color 0.3s;
  }

}
