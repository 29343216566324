.Page_us {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.Page_us .main {
    width: 100%;
    min-height: 860px;
    background-image: url("../../assets/images/images/Usimg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Page_us .main .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 70%;
    height: 80%;
    border-radius: 4px;
    background: #1C1C1F;
    box-shadow: 1px 2px 10px 2px rgba(0, 0, 0, 0.15);
}

.Page_us .main .container .right_part {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Page_us .main .container .left_part {
    background-color: #fff;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
}

.Page_us .main .container .left_part .title {
    width: 90%;
    text-align: left;
    color: #000;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.Page_us .main .container .left_part .parg {
    width: 90%;
    text-align: left;
    color: #545454;
    text-align: justify;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 162.5%; /* 26px */
    margin-bottom: 15px;
}

.responsive-logo {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 1025px) {
    .Page_us .main .container {
        flex-direction: column;
        width: 90%;
        height: auto;
    }

    .Page_us .main .container .left_part,
    .Page_us .main .container .right_part {
        width: 100%;
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .Page_us .main .container .left_part .title {
        font-size: 20px;
        text-align: center;
        width: 100%;
    }

    .Page_us .main .container .left_part .parg {
        font-size: 14px;
        width: 100%;
        text-align: center;
    }

    .Page_us .main {
        min-height: auto;
        padding: 20px 0;
    }

    .responsive-logo {
        max-width: 70%;
        margin: 20px 0;
    }
}

/* Additional responsiveness for very small screens */
@media screen and (max-width: 480px) {
    .Page_us .main .container {
        width: 95%;
    }

    .Page_us .main .container .left_part .title {
        font-size: 18px;
    }

    .Page_us .main .container .left_part .parg {
        font-size: 13px;
    }

    .responsive-logo {
        max-width: 80%;
    }
}