.Searchbar{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 225px;
    width: 100%;
    background-image: url("../../assets/images/images/ville.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #043263;
}

.Searchbar .container{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
}


.Searchbar .container .title{
    padding: 0;
    margin: 0;
    color: #FFF;
    font-family: "Poppins";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}


.Searchbar .container .row{
    width: 90%;
    margin-top: 2rem;
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.Searchbar .container .row .text{
    color: #FFF;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}

.Searchbar .container .row button {
    padding: 0 2.5rem;
    min-width: 120px;
    height: 50px;
    border-radius: 4px;
background: #FF6300;
border: none;
display: flex;
align-items: center;
justify-content: center;
max-width: 200px;
}

.Searchbar .container .row button p{
    color: #FAFAFA;
text-align: center;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}



.Searchbar .container .column{
    margin-top: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.Searchbar .container .column .offre_title{
    padding: 0;
    margin: 0;
    color: #FFF;
font-family: "Poppins";
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
}


.Searchbar .container .column .row .el{
    margin-left: 1rem;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.Searchbar .container .column .row .el p{
    width: fit-content;
    white-space: nowrap;
    color: #D9D9D9;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
}


.Searchbar .container .column .row .el img{
    margin-right: 0.5rem;
}
.input-container input {
    width: 200px;
    height: 50px;
    padding-left: 20px;
    font-family: "Poppins";
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .input-container input:focus {
    outline: none;
    border-color: #043263;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    position: relative;
  }


/* For tablets and smaller screens */


/* For medium screens (landscape tablets, smaller laptops) */
@media (max-width: 768px) {
    .Searchbar {
        height: auto;
        padding: 1rem 0;
    }
    .Searchbar .container {
        width: 100%;
        align-items: center;
    }
    .Searchbar .container .row {
        flex-direction: column;
        align-items: center;
    }
    .Searchbar .container .row .text {
        font-size: 12px;
    }
    .Searchbar .container .row button {
        width: 40%;
        
    }
    .Searchbar .container .column .offre_title {
        font-size: 20px;
        text-align: center;
    }
}

/* For small screens (portrait tablets and larger phones) */
@media (max-width: 600px) {
    .Searchbar {
        height: auto;
        padding: 1rem 0;
    }
    .Searchbar .container {
        width: 100%;
        align-items: center;
    }
    .Searchbar .container .row {
        flex-direction: column;
        align-items: center;
    }
    .Searchbar .container .row .text {
        font-size: 12px;
    }
    .Searchbar .container .row button {
        width: 40%;
    }
    .Searchbar .container .column .offre_title {
        font-size: 20px;
        text-align: center;
    }
}

/* For extra-small screens (mobile phones) */
@media (max-width: 400px) {
    .Searchbar {
        height: auto;
        padding: 0.5rem;
    }
    .Searchbar .container .title {
        font-size: 24px;
        text-align: center;
    }
    .Searchbar .container .row {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    .Searchbar .container .column .row .el {
        margin: 0.5rem 0;
    }
    .Searchbar .container .column .row .el p {
        font-size: 14px;
    }
    .Searchbar .container .row button {
        width: 40%;
        padding: 0 1rem;
    }
}
