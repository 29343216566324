.Page_profile{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.Page_profile .p1{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.Page_profile .p1 .cover_image{
width: 100%;
height: 180px;
object-fit: cover;

}

.cover_image3{
    width: 150px; /* Adjust the size as necessary */
    height: 150px; /* Ensure height is equal to width for a perfect circle */
    border-radius: 50%;
    object-fit: cover; /* Ensures the image is not stretched and keeps its aspect ratio */
    border: 2px solid #ccc; /* Optional: add a border to make the circle stand out */
  }

.Page_profile .p1 .informations{
    position: absolute;
    top: 115px;
    height: 130px;
    width: 100%;
    display: flex;
    flex-direction: row;

}

.Page_profile .p1 .informations .profile_image{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 130px;
    height: 130px;
    object-fit:cover;
    border-radius: 50%;
    margin-left: 4rem;

}

.Page_profile .p1 .informations .profile_image .edit{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: #fff;

    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.Page_profile .p1 .informations .profile_image .edit img{
    width: 20px;
    height: 20px;
}
.Page_profile .p1 .informations .info{
    margin-left: 4rem;
        height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.Page_profile .p1 .informations .info .name{
    margin: 0;
    padding: 0%;
    color: #FFF;
font-family: "Poppins";
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 44.8px */
letter-spacing: 5.92px;
}
.Page_profile .p1 .informations .info .date{
    margin: 0;
    margin-bottom: 1rem;
    padding: 0%;
    color: #000;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 22.632px; /* 113.158% */
}


.Page_profile .p1 .informations .points{
    padding: 0 1rem;

    margin: auto 2rem auto auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 45px;
    border-radius: 4px;
background: #FFA800;
}

.Page_profile .p1 .informations .points p{
    white-space: nowrap;
    color: #FFF;
font-family: "Open Sans";
font-size: 1.2rem;
font-style: normal;
font-weight: 700;
line-height: 22.632px; /* 125% */
}

.Page_profile .p2{
    margin-top: 200px;
    width: 90%;
    min-height: 300px;
    display: flex;
    align-items: top;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 10rem;

}


.Page_profile .p2 .right_part{
    min-height: 300px;
    width: 30%;
    border-radius: 4px;
border: 1px solid #AEAEAE;
}
.Page_profile .p2 .right_part .el{
    width: fit-content;
    position: relative;
    cursor: pointer;
    margin-left: 2rem;
    color: #8B8B8B;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 22.632px; /* 141.447% */
}
.Page_profile .p2 .right_part .el.selected{
    color: #043263;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 22.632px; /* 141.447% */
}
.Page_profile .p2 .right_part .el:hover{
    color: #043263;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.632px; /* 141.447% */
}
.Page_profile .p2 .right_part .el .line{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background: #239EFE;
    transition: all .2s ease-out;
}
.Page_profile .p2 .left_part{
    padding: 2rem 0;
    width: 65%;
    min-height: 300px;
    border-radius: 4px;
border: 1px solid #AEAEAE;
}

.Page_profile .p2 .right_part .el {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Page_profile .p2 .right_part .el .remise{
    color: #FFA800;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 22.632px; /* 141.447% */
}
.Page_profile .p2 .right_part .el.coupons{
    opacity:.6
}
.Page_profile .p2 .right_part .el.coupons:hover{
    opacity: .9;
}
.Page_profile .p2 .right_part .el img{
    margin-left: 1rem;
}

input{
    color: #4F4F4F;
  font-family: "Poppins";
  font-size: 16.974px;
  font-style: normal;
  font-weight: 600;
}


@media screen and (max-width: 768px) {
    .Page_profile .p2 {
        flex-direction: column;
        margin-top: 100px;
    }

    .Page_profile .p2 .right_part,
    .Page_profile .p2 .left_part {
        width: 100%;
        margin-bottom: 1rem;
    }

    .Page_profile .p1 .informations {
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: static;
        height: auto;
        margin-top: 50px;
    }

    .Page_profile .p1 .informations .profile_image {
        margin-left: 0;
        margin-bottom: 1rem;
    }

    .Page_profile .p1 .informations .info {
        margin-left: 0;
        align-items: center;
    }

    .Page_profile .p1 .informations .points {
        margin: 1rem auto;
    }

    .Page_profile .p1 .informations .info .name {
        font-size: 24px;
    }

    .Page_profile .p2 .right_part .el {
        margin: 1rem 0;
        width: 100%;
        text-align: center;
        justify-content: center;
    }
}
