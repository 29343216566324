.devis-section {
  padding: 20px 30px;
  background: linear-gradient(135deg, #f9f9f9 0%, #fff 100%);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 30px auto;
  text-align: start;
  width: 50%;
  max-width: 90%; /* Adjust for smaller screens */
}

.devis-section h2 {
  color: #ff6600;
  font-size: 1.8em;
  margin-bottom: 20px;
  text-align: center;
}

.section {
  margin-bottom: 20px;
}

.places-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Center the cards for small screens */
}

.place-item {
  width: calc(100% / 3 - 20px); /* Default: 3 items per row */
  max-width: 300px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
}

.place-item:hover {
  transform: translateY(-5px);
}

.place-item h4 {
  color: #ff6600;
  margin-bottom: 10px;
}

.place-item p {
  color: #555;
  font-size: 0.9em;
}

.place-item.unavailable {
  opacity: 0.6;
  cursor: not-allowed;
}

hr {
  border: 1px solid #eee;
  margin: 20px 0;
}

.total-section {
  text-align: center;
  font-size: 1.2em;
  color: #333;
  display: flex;
  flex-direction: flex;
  justify-content: space-around;
  gap: 10px;
  padding: 10px;
}

.total-section > p {
  margin-top: 10px;
  align-content: end;
  padding-bottom: 5px;
}

.back-button,
.next-button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #ff6600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.back-button {
  background-color: #afafaf;
}

.next-button:hover {
  background-color: #e05500;
}

.place-item.selected {
  border: 2px solid #ff6600;
}

.no-places-message {
  color: #ff6600;
  font-weight: bold;
  font-size: 1.1em;
  margin-top: 10px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .devis-section {
    width: 70%; /* Reduce width for medium screens */
  }

  .places-list {
    justify-content: flex-start; /* Left-align on medium screens */
  }

  .place-item {
    width: calc(100% / 2 - 20px); /* 2 items per row on medium screens */
  }
}

@media (max-width: 768px) {
  .devis-section {
    width: 90%;
  }

  .place-item {
    width: 100%; /* Single item per row on small screens */
  }

  .total-section {
    text-align: start; /* Align text to the left */
  }

  .back-button,
  .next-button {
    
    width: 150px;
  }
}

@media (max-width: 480px) {
  .devis-section h2 {
    font-size: 1.5em; /* Smaller font size for headings */
  }

  .place-item {
    padding: 15px;
  }

  .total-section {
    font-size: 1em;
  }

  .back-button,
  .next-button {
    width: 120px;
    font-size: 0.9em; /* Adjust button font size */
  }
}
