.Volcard {
    width: 100%;
    height: auto; /* Allow height to adjust automatically */
    padding: 10px; /* Add padding for better spacing */
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 20px;
    margin: 20px 0;
    border-radius: 4px; /* Slightly larger for modern UI */
    border: 1px solid #e0e0e0;
    background-color: #ffffff; /* Ensure a consistent background */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visibility */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}



.Volcard .vol_left_part {
    background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.4)
        ),
        url('../../assets/images/images/ailogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    min-width: 100px; /* Prevent collapsing on small screens */
    border-radius: 4px;
    color: white;
    padding: 20px;
    text-align: center;
}

.Volcard .vol_left_part .name {
    margin: 0.5rem 0 0.2rem;
    padding: 0.2rem 0.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
}

.Volcard .vol_left_part .type {
    margin: 0;
    padding: 0.2rem 0.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
}

.Volcard .vol_center_part {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 10px; /* Add padding for better spacing */
}

.Volcard .vol_center_part p {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    color: #4a4a4a;
}

.Volcard .vol_center_part span {
    font-weight: 600;
    color: #2b2b2b;
    display: flex;
    align-items: center;
}

.Volcard .vol_center_part span img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-right: 8px;
}

.Volcard .vol_center_part .departure,
.Volcard .vol_center_part .arrival,
.Volcard .vol_center_part .destination {
    border-left: 3px solid #4caf50;
    padding-left: 10px;
}

.Volcard .vol_center_part .duration {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Volcard .vol_center_part .duration img {
    margin-right: 8px;
}

.Volcard .vol_right_part {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between; /* Adjust alignment for better layout */
    padding: 10px; /* Add spacing */
}

.Volcard .vol_right_part .date {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    color: #9e9e9e;
}

/* Improved Media Queries */
@media (max-width: 768px) {
    .Volcard {
        flex-direction: column; /* Stack items vertically */
        align-items: center;
        gap: 10px; /* Reduce gap for smaller screens */
    }

    .Volcard .vol_left_part {
        width: 90%; /* Full width for smaller screens */
        min-height: 150px; /* Maintain visibility */
        border-radius: 8px;
    }

    .Volcard .vol_center_part,
    .Volcard .vol_right_part {
        width: 100%; /* Adjust width */
        text-align: center; /* Center text for better alignment */
    }

    .Volcard .vol_center_part p {
        font-size: 14px; /* Adjust font size */
    }
}

@media (max-width: 480px) {
    .Volcard {
        padding: 10px;
        gap: 8px; /* Reduce gap for very small screens */
    }

    .Volcard .vol_left_part {
        min-height: 120px; /* Reduce height */
    }

    .Volcard .vol_center_part p,
    .Volcard .vol_right_part .date {
        font-size: 13px; /* Adjust font size */
    }
}
