.Page_hotel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}





.Page_hotel.rtl {
  direction: rtl;
}





.Page_hotel .main {
  margin-top: 5rem;
  width: 90%;
}

.Page_hotel .main .p1_big_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.Page_hotel .main .p1_big_container .top_part {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Page_hotel .main .p1_big_container .top_part .left_part {
  width: 45%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  
}

.Page_hotel .main .p1_big_container .top_part .left_part .title {
  margin-bottom: 0.5rem;
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: #043263;
}

.Page_hotel .main .p1_big_container .top_part .left_part .rates {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.Page_hotel .main .p1_big_container .top_part .left_part .rates img {
  width: 26px;
  height: 26px;
}

.Page_hotel .main .p1_big_container .top_part .left_part .rates p {
  margin: 0;
  margin-left: 0.5rem;
  padding: 0;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #fadb14;
}

.Page_hotel .main .p1_big_container .top_part .left_part .desc {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #6d6d6d;
}

.Page_hotel .main .p1_big_container .top_part .left_part .desc_text {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: "justified";
  color: #131313;
  width: 100%;
  margin-bottom: 3rem;



word-break: break-word; 
word-break: break-all; 
overflow-wrap: break-word; 

}

.Page_hotel .main .p1_big_container .top_part .left_part .price {
  margin-top: 0;
}
.Page_hotel .main .p1_big_container .top_part .left_part .price .blue_tag {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #239efe;
}
.Page_hotel .main .p1_big_container .top_part .left_part .price .light_tag {
  font-weight: 400;
}

.Page_hotel .main .p1_big_container .top_part .left_part .location {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.Page_hotel .main .p1_big_container .top_part .left_part .location p {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #131313;
}
.Page_hotel .main .p1_big_container .top_part .left_part .location img {
  filter: brightness(0) saturate(100%) invert(38%) sepia(0%) saturate(1%)
    hue-rotate(170deg) brightness(94%) contrast(86%);
  margin-right: 0.5rem;
}

.Page_hotel .main .p1_big_container .top_part .left_part .services {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 10rem;
}

.Page_hotel .main .p1_big_container .top_part .left_part .services .service {
  margin: 0.2rem 0.4rem;
  height: fit-content;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #239efe;
  padding: 0.7rem 1.7rem;
  border: 1px solid #239efe;
  border-radius: 4px;
}

.Page_hotel .main .p1_big_container .top_part .right_part {
  height: 100%;
  padding: 0;
  margin: 0;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.swiper-pagination-bullet {
  width: 11px !important;
  height: 11px !important;
  border-radius: 4px !important;
  border: 1px solid white !important;
  opacity: 1 !important;
  background-color: transparent !important;
}

.swiper-pagination-bullet-active {
  border: 1px solid #ff6300 !important;
  width: 11px !important;
  height: 11px !important;
  border-radius: 4px !important;
  background-color: #ff6300 !important;
}

.Page_hotel .main .p1_big_container .bottom_part {
    margin-top: 4rem;
    height: 60px;
    margin-bottom: 10rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #999;
}
.Page_hotel .main .p1_big_container .bottom_part .input1{
    cursor: pointer;
    flex: 3;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    opacity: .95;
    position: relative;
}
.Page_hotel .main .p1_big_container .bottom_part .input1 .personnes_input {
    cursor: pointer;
  width: 100%;
    height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  opacity: .95;
}
.Page_hotel .main .p1_big_container .bottom_part .input1 .personnes_input:hover{
    opacity: 1;
}
.Page_hotel .main .p1_big_container .bottom_part .input1 .personnes_input .icon{
    margin: auto .7rem;
}
.Page_hotel .main .p1_big_container .bottom_part .input1 .personnes_input .click_icon{
    margin: auto .7rem auto auto;
}

.Page_hotel .main .p1_big_container .bottom_part .input1 .personnes_input .placeholder{
    color: #B6B6B6;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 152%;
letter-spacing: 0.7px;
}



.Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div {
    position: absolute;
    bottom: -150px;
    overflow: hidden;
    height: 150px;
    animation: slideDown 0.3s ease-out;
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  }
  


  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes slideUp {
    0% {opacity: 1;
      transform: translateY(0);
     
    }
    100% {
       opacity: 0;
      transform: translateY(-300px);
    }
  }
  
  .Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div.expanded {
    animation: slideUp 0.3s ease-out forwards;
  }
  

  
  .Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div button {
    margin-bottom: 10px;
  }
  
  .Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div .content {
    cursor: default;
    /* Additional styling for the content */
    width:100%;
    height:100%;
  }

  .Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div .content .row{
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
  }
  .Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div .content .row .icon{
    margin: auto .7rem;
  }
  .Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div .content .row .title{
    color: #2B2A2A;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 152%;
letter-spacing: 0.7px;
  }
  .Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div .content .row .faded_text{
    color: #A6A6A6;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 152%;
letter-spacing: 0.7px;
margin-left: .5rem;
  }
  .Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div .content .row .perssoneshandler{

    margin: auto .7rem auto auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div .content .row .perssoneshandler img:hover{
    opacity: 1;
  }
  .Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div .content .row .perssoneshandler .disabled:hover{
    opacity: .92;
  }
  .Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div .content .row .perssoneshandler img{
    opacity: .92;
    cursor: pointer;
    margin: auto .4rem;
  }

  .Page_hotel .main .p1_big_container .bottom_part .input1 .sliding-div .content .row .perssoneshandler .disabled{
    filter: invert(93%) sepia(0%) saturate(0%) hue-rotate(142deg) brightness(85%) contrast(83%);
  }

  .Page_hotel .main .p1_big_container .bottom_part .input2{
    cursor: pointer;
    flex: 3;
    height: 100%;
    border-left: 1px solid #999;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    opacity: .95;
    position: relative;
    
  }
  .Page_hotel .main .p1_big_container .bottom_part .input2 img{
    margin:auto .7rem;
  }
  .Page_hotel .main .p1_big_container .bottom_part .input2 input{
    color: #B6B6B6;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 152%;
letter-spacing: 0.7px;
    width: 100%;
    height: 100%;
    border: none;
  }
  .Page_hotel .main .p1_big_container .bottom_part .input2 input:focus{
    outline: none;
  }


  .Page_hotel .main h2 {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #6d6d6d;
    margin-top: 2rem;
    margin-bottom: 1rem;
    
  }
  
  .Page_hotel .main .p2 {
    width: 100%;
    display: flex;
  
    gap: 5rem;
    padding: 1rem 0;
}

  .Page_hotel .main .map{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
   
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .Page_hotel .main .map .title{
    color: #6D6D6D;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1.6px;
text-transform: uppercase;
  }

  .Page_hotel .main .map .map_location{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 410px;
    
  }



/* Tablet Breakpoint */
@media screen and (max-width: 1024px) {
  .Page_hotel .main {
    width: 95%;
  }

  .Page_hotel .main .p2 {
    justify-content: center;
    gap: 1.5rem;
  }

  .Page_hotel .main .p1_big_container .top_part {
    flex-direction: column;
    align-items: center;
  }

  .Page_hotel .main .p1_big_container .top_part .left_part,
  .Page_hotel .main .p1_big_container .top_part .right_part {
    width: 100%;
    max-width: 600px;
    margin-bottom: 2rem;
  }

  .Page_hotel .main .p2 {
    justify-content: center;
  }

  .swiper {
    width: 100% !important;
  }
}

/* Mobile Breakpoint */
@media screen and (max-width: 768px) {
  .Page_hotel .main {
    margin-top: 2rem;
  }

  

  .Page_hotel .main .p1_big_container .top_part .left_part .title {
    font-size: 20px;
  }

  .Page_hotel .main .p1_big_container .top_part .left_part .desc_text {
    font-size: 12px;
  }

  .Page_hotel .main .p1_big_container .top_part .left_part .services {
    height: auto;
    flex-wrap: wrap;
  }

  .Page_hotel .main .p1_big_container .top_part .left_part .services .service {
    margin: 0.2rem;
    padding: 0.5rem 1rem;
    font-size: 11px;
  }

  .Page_hotel .main .p2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
  }


}

/* Small Mobile Breakpoint */
@media screen and (max-width: 480px) {
  .Page_hotel .main {
    width: 98%;
  }

  .Page_hotel .main .p1_big_container .bottom_part {
    flex-direction: column;
    height: auto;
  }

  .Page_hotel .main .p1_big_container .bottom_part .input1,
  .Page_hotel .main .p1_big_container .bottom_part .input2 {
    width: 100%;
    border: none;
    border-bottom: 1px solid #999;
  }

  .Page_hotel .main .p2 {
    margin: 0;
    gap: 1rem;
    padding: 0.5rem;
    width: 90%;
  }


}

/* RTL specific font family */
.Page_hotel.rtl .title,
.Page_hotel.rtl .desc,
.Page_hotel.rtl .desc_text,
.Page_hotel.rtl .location_text,
.Page_hotel.rtl .service,
.Page_hotel.rtl h2 {
  font-family: "Tahoma", "Poppins", sans-serif;
}

  




  