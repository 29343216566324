.Checkout_hotel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

.Checkout_hotel .main {
  margin: 10rem 0;
  margin-top: 1rem;
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.Checkout_hotel .main .big_title {
  margin-bottom: 2rem;
  color: #292930;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.Checkout_hotel .main .container {
  width: 100%;
  height: 840px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  }
  .Checkout_hotel .main .container .form{
    padding: 0 0;
    padding-right: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #AEAEAE;
    width: 47%;
  }
.Checkout_hotel .main .container .form form {
  width: 85%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  
}

.Checkout_hotel .main .container .form form .title {
  color: #000;
  font-family: "Open" "Sans";
  font-size: 18.105px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.632px;
  margin-bottom: 2rem;
}

/* tow inputs */

.Checkout_hotel .main .container .form form div label input:focus{
    outline: none;
    border: 1px solid #239EFE;

}


.Checkout_hotel .main .container .form form .two_inputs label .input_label.z{
    z-index: 999 !important;
}
.Checkout_hotel .main .container .form form .two_inputs label .input_label{

    padding: 0 .3rem;
    background-color: #fff;
    position: absolute;
    top: -26px;
    left: 15px;


    color: #828282;
font-family: "Poppins";
font-size: 13.579px;
font-style: normal;
font-weight: 400;
line-height: 22.632px;
}

.Checkout_hotel .main .container .form form .two_inputs {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  width: 100%;
}

.Checkout_hotel .main .container .form form .two_inputs label {
    width: 45%;
    height: 50px;
  position: relative;
}
.Checkout_hotel .main .container .form form .two_inputs label input,select {
    outline: none;
    padding-left: 1rem;
  border-radius: 4.526px;
  border: 1.132px solid #b2bcca;
  background: #fff;
  width:100%;
  min-width: 150px;
  height: 50px;
}

.Checkout_hotel .main .container .form form .one_input label select{
  color: #c5c5c5;
  font-family: "Poppins";
  font-size: 16.974px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.632px;
}

.Checkout_hotel .main .container .form form .two_inputs label select{
  color: #c5c5c5;
  font-family: "Poppins";
  font-size: 16.974px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.632px;
}

/* Style for the select when an option is selected */
.Checkout_hotel .main .container .form form .two_inputs label select:not([value=""]) {
  color: #4F4F4F;
}

/* Style for the options in the dropdown */
.Checkout_hotel .main .container .form form .two_inputs label select option {
  color: #4F4F4F;
}

/* Style for the placeholder option */
.Checkout_hotel .main .container .form form .two_inputs label select option[value=""] {
  color: #4F4F4F;
}



/* Style for the select when an option is selected */
.Checkout_hotel .main .container .form form .one_input label select:not([value=""]) {
  color: #4F4F4F;
}

/* Style for the options in the dropdown */
.Checkout_hotel .main .container .form form .one_input label select option {
  color: #4F4F4F;
}

/* Style for the placeholder option */
.Checkout_hotel .main .container .form form .one_input label select option[value=""] {
  color: #4F4F4F;
}


.Checkout_hotel .main .container .form form .two_inputs label input::placeholder{
  color: #c5c5c5;
font-family: "Poppins";
font-size: 16.974px;
font-style: normal;
font-weight: 600;
line-height: 22.632px;
}

/* one input */

.Checkout_hotel .main .container .form form .one_input label .input_label{
    padding: 0 .3rem;
    background-color: #fff;
    position: absolute;
    top: -26px;
    left: 15px;


    color: #828282;
font-family: "Poppins";
font-size: 13.579px;
font-style: normal;
font-weight: 400;
line-height: 22.632px;
}

.Checkout_hotel .main .container .form form .one_input {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  width: 100%;
}

.Checkout_hotel .main .container .form form .one_input label {
    width: 100%;
    height: 50px;
  position: relative;
}
.Checkout_hotel .main .container .form form .one_input label input,select {
    outline: none;
    padding-left: 1rem;
  border-radius: 4.526px;
  border: 1.132px solid #b2bcca;
  background: #fff;
  width:100%;
  min-width: 250px;
  height: 50px;
}


.Checkout_hotel .main .container .form form .one_input label input::placeholder{
  color: #c5c5c5;
font-family: "Poppins";
font-size: 16.974px;
font-style: normal;
font-weight: 600;
line-height: 22.632px;
}

.css-qbdosj-Input{
    margin: 0 !important;
}
.css-qbdosj-Input{
    height: 99% !important;
    padding: 0 !important;
    margin: 0 !important;
}


.Checkout_hotel .main .container .form form .check{
  margin: 1.5rem 0;
    width: 100%;
}

.Checkout_hotel .main .container .form form .check label{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.Checkout_hotel .main .container .form form .check label .text{
  margin-left: .5rem;
    color: #4F4F4F;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

}
.Checkout_hotel .main .container .form form .check label input{
  width: 13px;
  height: 13px;
  cursor: pointer;
}
.Checkout_hotel .main .container .form form .check label .text a{
    text-decoration: none;
    border: none;
    color: #239EFE;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

}


.Checkout_hotel .main .container .facture{
  width: 46%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

}
.Checkout_hotel .main .container .facture .image{
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}
.Checkout_hotel .main .container .facture .content{
  height: 70%;
  border: 1px solid #AEAEAE;
  padding-bottom: 2rem;
border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.Checkout_hotel .main .container .facture .content .title{
 width: 90%;
  color: #000;
font-family: "Poppins";
font-size: 18.415px;
font-style: normal;
font-weight: 700;
line-height: 23.019px;
}

.Checkout_hotel .main .container .facture .content .el{
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.Checkout_hotel .main .container .facture .content .el .info{
  color: #4F4F4F;
font-family: "Poppins";
font-size: 16.113px;
font-style: normal;
font-weight: 400;
line-height: 23.019px;
}

.Checkout_hotel .main .container .facture .content .el .price{
  color: #4F4F4F;
text-align: right;
font-family: "Poppins";
font-size: 17.264px;
font-style: normal;
font-weight: 600;
line-height: 23.019px;
}

.Checkout_hotel .main .container .facture .content .el .golden_tag{
  color: #FFA800;
font-family: "Poppins";
font-size: 16.113px;
font-style: normal;
font-weight: 700;
line-height: 23.019px;
}

.Checkout_hotel .main .container .facture .content .el input {
  height: 50px;
  padding-left: 1rem;
  width: 30%;
  min-width: 230px;
  border-radius: 4px;
border: 1px solid #DBDBDB;
background: #FFF;
}

.Checkout_hotel .main .container .facture .content .el input{
  color: #4F4F4F;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.Checkout_hotel .main .container .facture .content .remise{
  width: 90%;
  text-align: right;
  color: #4F4F4F;
font-family: "Poppins";
font-size: 17.264px;
font-style: normal;
font-weight: 600;
line-height: 23.019px;
}
.Checkout_hotel .main .container .facture .content .el .total{
  color: #000;
font-family: "Poppins";
font-size: 18.415px;
font-style: normal;
font-weight: 700;
line-height: 23.019px;
}
.Checkout_hotel .main .container .facture .content .el .total_price{
  color: #000;
  text-align: right;
  font-family: "Poppins";
  font-size: 17.264px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.019px;
}


.Checkout_hotel .main .container .facture .content .el button{
  cursor: pointer;
  min-width: 230px;
  width: 40%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius:5px ;
  border: none;
  opacity: .95;
}
.Checkout_hotel .main .container .facture .content .el button:hover{
  opacity: 1;
}

.Checkout_hotel .main .container .facture .content .el .payement1{
  background-color: #239EFE;
  min-width: 150px;
}
.Checkout_hotel .main .container .facture .content .el .payement2{
  background-color: #a5a5a5;
  min-width: 150px;
}
.Checkout_hotel .main .container .facture .content .el button p{
  color: #FFF;
text-align: center;
font-family: "Poppins";
font-size: 13.028px;
font-style: normal;
font-weight: 500;
line-height: 18.612px;
text-transform: capitalize;
}
.Checkout_hotel .main .container .facture .content .line{
  width: 90%;
  height: 1px;
  background-color: #E0E0E0;
}
.Checkout_hotel .main .container .facture .content .el button img{
  margin: auto .5rem auto 0;
}










.Checkout_hotel .main .container .form form .two_inputs label .css-1hb7zxy-IndicatorsContainer{
  position: absolute;
  right: 10px;
  top: 5px;
}
.Checkout_hotel .main .container .form form .two_inputs label .css-b62m3t-container{
  width: 100%;
}


@media screen and (max-width: 1024px) {
  .Checkout_hotel .main {
    width: 95%;
  }

  .Checkout_hotel .main .container {
    flex-direction: column;
    height: auto;
  }

  .Checkout_hotel .main .container .form,
  .Checkout_hotel .main .container .facture {
    width: 100%;
    margin-bottom: 2rem;
  }

  .Checkout_hotel .main .container .form form .two_inputs label input,
  .Checkout_hotel .main .container .form form .one_input label input {
    min-width: 100%;
  }

  .Checkout_hotel .main .container .facture .content .el button,
  .Checkout_hotel .main .container .facture .content .el input {
    min-width: 150px;
   
  }

  
}

/* Mobile Styles (up to 767px) */
@media screen and (max-width: 767px) {
  .Checkout_hotel .main {
    margin: 2rem 0;
    width: 95%;
  }

  .Checkout_hotel .main .big_title {
    font-size: 24px;
  }

  .Checkout_hotel .main .container .form form .two_inputs,
  .Checkout_hotel .main .container .form form .one_input {
    flex-direction: column;
  }

  .Checkout_hotel .main .container .form form .two_inputs label,
  .Checkout_hotel .main .container .form form .one_input label {
    width: 100%;
    margin-bottom: 1rem;
  }

  .Checkout_hotel .main .container .form form .title {
    font-size: 16px;
  }



  .Checkout_hotel .main .container .facture .content .el .info,
  .Checkout_hotel .main .container .facture .content .el .price,
  .Checkout_hotel .main .container .facture .content .el .total,
  .Checkout_hotel .main .container .facture .content .el .total_price {
    font-size: 14px;
  }




  .Checkout_hotel .main .container .facture .content .el button p {
    font-size: 12px;
  }
}

/* Small Mobile Styles (up to 480px) */
@media screen and (max-width: 480px) {
  .Checkout_hotel .main .container .form form {
    width: 90%;
  }
  .Checkout_hotel .main .container .facture .content .caso{
    margin-top: 1rem;;
    flex-direction: column;
    gap:10px;
    
  }

  .Checkout_hotel .main .container .form form .two_inputs label .input_label,
  .Checkout_hotel .main .container .form form .one_input label .input_label {
    font-size: 12px;
  }

  .Checkout_hotel .main .container .form form .two_inputs label input::placeholder,
  .Checkout_hotel .main .container .form form .one_input label input::placeholder {
    font-size: 14px;
  }

  .Checkout_hotel .main .container .facture .content .el button{
    width: 100%;
  }
}



.react-datepicker__current-month {
  color: white; /* Change the month text color to white */
}