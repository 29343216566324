.presentation {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  border: 0px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 50px;
}

.presentation-image {
  width: 100%;
  height: auto;
  max-height: 400px;
  margin: 10px 0;
  object-fit: cover;
  border-radius: 5px;
}

.presentation-details {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.presentation h2 {
  color: #ff6600;
  font-size: 1.8em;
  margin-bottom: 15px;
  font-weight: bold;
}

.icon-text {
  display: flex;
  align-items: center;
  font-size: 1em;
  color: #555;
  margin: 8px 0;
}

.icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.description {
  margin-top: 10px;
  font-size: 0.95em;
  color: #555;
  line-height: 1.6;
  text-align: justify;
}

.plan-de-voyage {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.plan-de-voyage strong {
  font-size: 1em;
  color: #555;
}

.document-link {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.document-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.document-link:hover .document-icon {
  transform: scale(1.15);
}

/* Responsive Styles for Tablet */
@media (min-width: 768px) and (max-width: 1024px) {
  .presentation {
    flex-direction: column;
    padding: 15px;
  }

  .presentation-image {
    width: 100%;
    max-height: 300px;
  }

  .presentation h2 {
    font-size: 1.5em;
  }

  .icon-text {
    font-size: 0.9em;
  }

  .description {
    font-size: 0.9em;
  }

  .document-icon {
    width: 22px;
    height: 22px;
  }
}

/* Responsive Styles for Mobile */
@media (max-width: 767px) {
  .presentation {
    flex-direction: column;
    padding: 10px;
  }

  .presentation-image {
    width: 100%;
    max-height: 250px;
  }

  .presentation h2 {
    font-size: 1.3em;
    text-align: center;
  }

  .icon-text {
    font-size: 0.85em;
    margin: 5px 0;
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  .description {
    font-size: 0.85em;
  }

  .plan-de-voyage {
    flex-direction: column;
    align-items: flex-start;
  }

  .document-link {
    margin: 10px 0 0;
  }

  .document-icon {
    width: 20px;
    height: 20px;
  }
}

/* Arabic (RTL) Version */
.rtl .presentation {
  font-family: "Tahoma", "Arial", sans-serif;
  text-align: right;
  direction: rtl;
}

.rtl .presentation h2 {
  font-family: "Tahoma", "Arial", sans-serif;
}

.rtl .icon-text {
  flex-direction: row-reverse;
}

.rtl .icon {
  margin-right: 0;
  margin-left: 8px;
}

.rtl .description {
  direction: rtl;
  text-align: right;
  font-family: "Tahoma", "Arial", sans-serif;
  unicode-bidi: embed;
  line-height: 1.8; /* Slightly increased for Arabic script */
}

.rtl .plan-de-voyage {
  flex-direction: row-reverse;
}

.rtl .document-link {
  margin-left: 0;
  margin-right: 10px;
}

/* Responsive Adjustments for RTL */
@media (max-width: 767px) {
  .rtl .presentation h2 {
    text-align: center;
  }
  
  .rtl .plan-de-voyage {
    align-items: flex-end;
  }
  
  .rtl .document-link {
    margin: 10px 0 0;
  }
}

/* Special RTL Typography Enhancements */
.rtl .presentation * {
  letter-spacing: 0; /* Arabic doesn't need letter spacing */
}

.rtl .presentation strong {
  font-weight: 600; /* Slightly bolder for better Arabic readability */
}
