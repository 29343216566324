.Support{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.Support .title{
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
}


.Support .title p{
    color: #000;
font-family: "Open Sans";
font-size: 18.105px;
font-style: normal;
font-weight: 700;
line-height: 22.632px; /* 125% */
}

.Support .title img{
    cursor: pointer;
    opacity: .8;
}
.Support .title img:hover{
    opacity: 1;
}


.Support .two_inputs label .input_label.z{
    z-index: 999 !important;
}
.Support .two_inputs label .input_label{

    padding: 0 .3rem;
    background-color: #fff;
    position: absolute;
    top: -26px;
    left: 15px;


    color: #828282;
font-family: "Poppins";
font-size: 13.579px;
font-style: normal;
font-weight: 400;
line-height: 22.632px;
}

.Support .two_inputs {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  width: 95%;
}

.Support .two_inputs label {
    width: 44%;
    height: 50px;
  position: relative;
}
.Support .two_inputs label input {
    outline: none;
    padding-left: 1rem;
  border-radius: 4.526px;
  border: 1.132px solid #b2bcca;
  background: #fff;
  width:96%;
  min-width: 70px;
  height: 50px;
}

.Support .two_inputs label .samurai {

  background: #f4f4f4;

}


.Support .two_inputs label input::placeholder{
    color: #4F4F4F;
font-family: "Poppins";
font-size: 16.974px;
font-style: normal;
font-weight: 600;
line-height: 22.632px;
}



.Support .two_inputs label .css-1hb7zxy-IndicatorsContainer{
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .Support .two_inputs label .css-b62m3t-container{
    width: 96%;
  }


  .Support .textfield {
    width: 95%;
  }
  
  .Support .textfield label {
    width: 100%;
    height: 50px;
  position: relative;
}
.Support .textfield label textarea {

    border-radius: 4.526px;
border: 1.132px solid #B2BCCA;
background: #FFF;



    padding-top: 1rem;
    height: 200px;
    outline: none;
    padding-left: 1rem;
    color: #7e7e7e;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.632px; /* 161.654% */
    width: 97.8%;
}


.Support .textfield label textarea::placeholder{
    color: #ABABAB;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.632px; /* 161.654% */
}


.Support .textfield label .input_label.z{
    z-index: 999 !important;
}
.Support .textfield label .input_label{
    translate: 0;
    padding: 0 .3rem;
    background-color: #fff;
    position: absolute;
    top: -330px;
    left: 15px;


    color: #828282;
font-family: "Poppins";
font-size: 13.579px;
font-style: normal;
font-weight: 400;
line-height: 22.632px;
}
.Support .send{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 
    15%;
    min-width: 200px;
    padding: 0 1rem;
    border-radius: 4px;
background: #FF6300;
margin:3rem 2rem 2rem auto;
border: none;
cursor: pointer;
}

.Support .send:hover{
    background: orange;
}

.Support .send p{
    padding: 0;
    margin: 0;
    color: #FAFAFA;
text-align: center;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}


@media (max-width: 768px) {
    .Support .two_inputs {
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
    }

    .Support .two_inputs label {
        width: 100%;
        margin-bottom: 1rem;
    }

    .Support .send {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .Support .title p {
        font-size: 16px;
    }

    .Support .two_inputs label input,
    .Support .textfield label textarea {
        font-size: 14px;
    }

    .Support .send p {
        font-size: 12px;
    }

    .Support .send {
        width: 100%;
        margin: 2rem auto;
    }
}