.Page_voyage {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Page_voyage .voyage-search {
    width: 100%;
    background-color: #043263;
    padding: 2rem 0;
    background-image: url("../../assets/images/images/ville.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.no-results{margin-right: 34%;}

.Page_voyage .voyage-search .container {
    width: 85%;
    margin: 50px auto;
}

.Page_voyage .voyage-search .search-row {
    display: flex;
    
    justify-content: center;
    gap: 2rem;
}

.Page_voyage .voyage-search .input-container {
    flex: 1;
    min-width: 200px;
}

/* Date Picker Specific Styles */
.Page_voyage .voyage-search .react-datepicker-wrapper {
    width: 100%;
}



.Page_voyage .voyage-search .datepicker {
    width: 90%;
    height: 50px;
    padding: 0 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    background: white;
    color: #4f4f4f;
}


.input-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #e4e4e4;
    font-size: 20px;
    font-family:Arial, Helvetica, sans-serif;
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
  }

/* Date Picker Dropdown Styles */
.react-datepicker {
    font-family: "Poppins", sans-serif;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.react-datepicker__header {
    background-color: #043263;
    color: white;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    background-color: #ff7b00;
}

.react-datepicker__day:hover {
    background-color: rgba(255, 123, 0, 0.2);
}

/* Price Input Styles */
.Page_voyage .voyage-search .price-input {
    width: 85%;
    height: 50px;
    padding: 0px 0px 0px 15px;
    margin: 0px 15px 0px 0px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    background: white;
    color: #4f4f4f;
}

/* Reset Button */
.reset-button {
    background-color: rgb(255, 123, 0);
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: large;
    color: white;
    height: 50px;
    width: 100%;
}

.reset-button:hover {
    background-color: rgba(255, 123, 0, 0.884);
}

/* Main Content Styles (Unchanged from previous CSS) */
.Page_voyage .main {
    margin-bottom: 3rem;
    margin-top: 2rem;
    padding: 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F8FCFF;
}

.Page_voyage .main .container {
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: end;
    padding-right: 12%;
}

.Page_voyage .main .container .Carouselcard {
    flex: 1 1 21%;
    max-width: 21%;
}

/* Pagination Styles (Unchanged) */
.Page_voyage .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;
    padding: 1rem;
}

.Page_voyage .pagination button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
}

.Page_voyage .pagination button:hover {
    background-color: #0056b3;
}

.Page_voyage .pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.Page_voyage .pagination span {
    font-size: 1rem;
    color: #333;
    font-weight: 500;
}

/* Media Queries (Unchanged from previous CSS) */
@media (max-width: 1200px) {
    .Page_voyage .main .container .Carouselcard {
        flex: 1 1 30%;
        max-width: 30%;
    }
}

@media (max-width: 876px) {
    .Page_voyage .main {
        margin-top: 2rem;
        padding: 1rem 0;
    }

    .Page_voyage .voyage-search .price-input {
        margin: 0px;
        padding-left: 5px;
        width: 100%;
        
    }

    .Page_voyage .voyage-search .container{
        width: 70%;
        margin: 0px auto;
    }
    .Page_voyage .voyage-search .input-container {
        width: 100%;
    }

    .Page_voyage .voyage-search .datepicker {
        width: 100%;
    }

    .Page_voyage .main .container .Carouselcard {
        flex: 1 1 100%;
        max-width: 100%;
        height: 500px;
    }

    .Page_voyage .voyage-search .search-row {
        flex-direction: column;
        align-items: stretch;
    }
}

@media (max-width: 640px) {
    .Page_voyage .pagination {
        flex-direction: column;
        gap: 0.75rem;
    }

    .Page_voyage .pagination button {
        width: 100%;
        padding: 0.75rem;
        font-size: 1rem;
    }

    .Page_voyage .pagination span {
        margin: 0.5rem 0;
    }

    .Page_voyage .main .container {
        padding-right: 15%;
    }
}

@media (max-width: 570px) {
    .Page_voyage .main .container {
        padding-right: 0%;
    }


}

@media (max-width: 500px) {
    .Page_voyage .main .container {
        justify-content: center;
    }

   
    .no-results{margin-right: 4%;}
}

@media (max-width: 355px) {
    .Page_voyage .main .container {
        padding-right: 5%;
    }
 
}

/* Remove padding and alignment */
.react-datepicker__input-container input {
    text-align: left; /* Align text to the left */
    padding: 0; /* Remove padding */
    margin: 0; /* Ensure no margin */
    width: 100%; /* Optional: Full width of container */
    box-sizing: border-box; /* Ensure padding and borders are within width/height */
  }
  
  /* Optional: Style the input field for better appearance */
  .react-datepicker__input-container input {
    border: 1px solid #ccc; /* Add border */
    border-radius: 4px; /* Rounded corners */
    height: 40px; /* Adjust height */
    font-size: 14px; /* Adjust font size */
  }
  