.Coupons{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.Coupons .title{
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
}


.Coupons .title p{
    color: #000;
font-family: "Open Sans";
font-size: 18.105px;
font-style: normal;
font-weight: 700;
line-height: 22.632px; /* 125% */
}

.Coupons .list{
    max-height: 500px;
    
    overflow-y:scroll ;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}