.Topbar{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #239EFE;
    direction: ltr !important;
}

.Topbar .informations{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


.Topbar .informations p{
    margin: 0 2rem;
}


.Topbar .informations p{
    color: #FFF;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.3px;
    text-transform: uppercase;
}

.Topbar .language{
    margin-right: 2rem;
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.Topbar .language p{
    padding: 0;
    margin: 0 .5rem;
}
.Topbar .language p{
    color: #FFF;
font-family: "Poppins";
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
}
.Topbar .language img{
    height: 20px;
    width: 20px;
}

button{
    border: none;
    margin: 10px;
}



@media (max-width: 768px) {
    .Topbar .informations{
        flex-direction: column;
    }
}
