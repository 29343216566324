/* reservationdemandes.css */

:root {

 

    --card-background: #ffffff;
    --pending-color: #FFC107;
    --approved-color: #4CAF50;
    --rejected-color: #F44336;
}

.reservationdemandes-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    
}

.reservations-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px 0;
}

.reservation-card {
    background-color: var(--card-background);
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.reservation-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.reservation-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: var(--primary-color);
}

.reservation-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    gap: 10px;
}

.reservation-card-title::before {
    content: '•';
    font-size: 1.8rem;
}

.reservation-details {
    display: grid;
    gap: 10px;
}

.reservation-detail {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
}

.reservation-detail-label {
    font-weight: 600;
    color: #666;
}

.reservation-detail-value {
    color: var(--text-color);
}

.reservation-status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
}

.status-pending {
    background-color: var(--pending-color);
    color: white;
}

.status-approved {
    background-color: var(--approved-color);
    color: white;
}

.status-rejected {
    background-color: var(--rejected-color);
    color: white;
}

.visa-card {
    border-left: 4px solid #3B82F6;
}

.hotel-card {
    border-left: 4px solid #10B981;
}

.voyage-card {
    border-left: 4px solid #8E44AD;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .reservations-grid {
        grid-template-columns: 1fr;
    }

    .reservation-card {
        width: 85%;
    }
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.reservation-card {
    animation: fadeIn 0.5s ease-out;
}

/* Print Styles */
@media print {
    .reservation-card {
        box-shadow: none;
        border: 1px solid #ddd;
    }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-color);
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
}

.pagination-button {
    background-color: var(--card-background);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pagination-button:hover {
    background-color: var(--primary-color);
    color: white;
}

.pagination-button.active {
    background-color: var(--primary-color);
    color: white;
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}