.Reservationcard{
    width: 25%;
    padding: 1rem 0;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #CBCBCB;
}

.Reservationcard .row{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
}

.Reservationcard .row .remboursable{
    color: #40CA1D;

/* Body/Regular/14 */
font-family: "Source Sans 3";
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.Reservationcard .row .non_remboursable{
    color: #FF5757;
font-family: "Source Sans 3";
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 20px;
}


.Reservationcard .row .price{
    color: #000;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;
}
.Reservationcard button{
    cursor: pointer;

    width: 80%;
    height: 45px;
    border: none;
    background-color: #FF6300;
    border-radius: 3.722px;
    margin-top: 1.8rem;

}
.Reservationcard button p{
    margin: auto;
    color: #fff;
font-family: "Source Sans 3";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}