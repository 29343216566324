.dates {
  font-family: Arial, sans-serif;
  padding: 20px 30px;
  background: linear-gradient(135deg, #f9f9f9 0%, #fff 100%);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-height: 300px;
  margin-top: 20px;
  margin-bottom: 50px;
  transition: box-shadow 0.3s, transform 0.2s;
  text-align: center;
}

.dates h2 {
  color: #ff6600;
  font-size: 1.8em;
  margin-bottom: 20px;
}

.dates-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 600px;
  text-align: left;
}

.date-item {
  padding: 10px 20px;
  margin-bottom: 12px;
  font-size: 1.1em;
  color: #555;
  background-color: #f4f4f4;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.date-item:hover {
  background-color: #fffbf2;
}

.date-item:last-child {
  margin-bottom: 0;
}

/* Responsive Styles for Tablet */
@media (min-width: 768px) and (max-width: 1024px) {
  .dates {
    padding: 20px 40px;
    min-height: 250px;
  }

  .dates h2 {
    font-size: 1.6em;
  }

  .dates-list {
    max-width: 500px;
  }

  .date-item {
    font-size: 1em;
    padding: 8px 15px;
  }
}

/* Responsive Styles for Mobile */
@media (max-width: 767px) {
  .dates {
    padding: 15px 20px;
    min-height: 200px;
    margin-bottom: 30px;
  }

  .dates h2 {
    font-size: 1.4em;
  }

  .dates-list {
    max-width: 100%;
  }

  .date-item {
    font-size: 0.95em;
    padding: 8px 12px;
  }
}
