.Page_home {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Page_home .visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.Page_home .holder {
  height: 120px;
  width: 50%;
  margin: auto 0;
  position: relative;
}

.Page_home .holder .search {
  position: absolute;
  z-index: 1;
  bottom: 50%;
  left: 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 1px 2px 36px 2px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.Page_home .holder .search {
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Page_home .holder .search button {
  cursor: pointer;
  margin-left: 1rem;
  min-width: 144px;
  height: 50px;
  border-radius: 4px;
  background-color: #043263;
  border: none;
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}
.Page_home .holder .search .search_input {
  height: 50px;
  width: 100%;
  background-color: #f4faff;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
.Page_home .holder .search .search_input img {
  position: absolute;
  left: 10px;
}
.Page_home .holder .search .search_input input {
  background-color: transparent;
  color: #b8b8b8;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  padding-left: 2.5rem;
  border: none;
  height: 50px;
}

.Page_home .holder .search .search_input .calendar {
  position: absolute;
  right: 0px;
  width: 45px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #239efe;
}

.Page_home .holder .search .search_input .calendar img {
  width: 24px;
  height: 24px;
}

.Page_home .holder .search .search_input input:focus {
  outline: 1px solid #239efe;
  border-radius: 4px;
}

.Page_home .header {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Page_home .header .title {
  margin-left: 4rem;
  color: #292930;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.Page_home .header .filters {
  min-width: 300px;
  margin-right: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Page_home .header .filters .filter {
  color: #cecece;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.Page_home .header .filters .filter.selected {
  position: relative;
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.Page_home .header .filters .filter.selected .border_bottom {
  width: 60%;
  height: 2px;
  background-color: #239efe;
  position: absolute;
  bottom: 0;
}

.Page_home .grid {
  margin-top: 3rem;
  width: 95%;
  padding: 0 2rem;
  padding-left: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px 5px;
  justify-content: center;
  align-items: center;
}

.Page_home .p1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.Page_home .p1 .button {
  margin: 3rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Page_home .p1 .button button {
  cursor: pointer;
  width: 196px;
  height: 50px;
  border-radius: 4px;
  background: #043263;
  border: none;
  opacity: 0.9;
}
.Page_home .p1 .button button:hover {
  opacity: 1;
}

.Page_home .p1 .button button {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.Page_home .p2 {
  height: 276px;
  width: 95%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
}
.Page_home .p2 .right_side{
  position: relative;
  width: 40%;
  height: 100%;
  background-image: url("../../assets/images/images/travelcard2.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;


}

.Page_home .p2 .right_side .logo{
  position:absolute;
  bottom: 20px;
  right: 30px;
}

.Page_home .p2 .right_side .logo2{
  display: none;
  position:absolute;
  bottom: 20px;
  right: 30px;
  width: 80px;
  height: 44px;
}

.Page_home .p2 .left_side {
  background: #F5FBFF;
  width: 60%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  
}

.Page_home .p2 .left_side .title {
  margin: 2rem 0 0 2rem;
  color: #696363;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
}

.Page_home .p2 .left_side .text {
  padding-right: 3rem;
  margin: 1rem 0 2rem 2rem;
  color: #696363;
  text-align: justify;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 149%;
  letter-spacing: 0.64px;
}

.Page_home .p2 .left_side button {
  margin-left: 2rem;

  margin-top: 1rem;
  border-radius: 4px;
  border: 1px solid #ff6300;
  background: #ff6300;
  width: 196px;
  height: 50px;
  cursor: pointer;
}
.Page_home .p2 .left_side button{
  color: #FFF;
text-align: center;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
}


.Page_home .p1 .slider{
  margin-top: 3rem;
  margin-bottom: 6rem;
  width: 95%;
}

.Page_home .p1 .header .redirect{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-right: 3rem;
}

.Page_home .p1 .header .redirect p{
  margin: 0;
  margin-right: 1rem;

  padding: 0;
  color: #696868;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 140%;

}

.Page_home .p1 .hotelscards{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 4rem;
  margin-bottom: 5rem;
  width: 95%;
}

.Page_home .p3{
  width: 95%;
  height: 426px;
  background-color:#F8F8F8;;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.Page_home .p3 .right_part{
  width: 40%;
  height: 100%;
}

.Page_home .p3 .right_part img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Page_home .p3 .left_part{
  height: 100%;
  width: 60%;
  padding: 0 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.Page_home .p3 .left_part .informations{ 
  margin-left: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

}

.Page_home .p3 .left_part .informations .title{
  margin: 1rem 0 .5rem 0;
  color: #696363;
font-family: "Poppins";
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 140%;
}

.Page_home .p3 .left_part .informations .disc{
  color: #696363;
text-align: justify;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 149%;
letter-spacing: 0.64px;
}

.Page_home .p3 .left_part .logos{
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.Page_home .p3 .left_part .logo{
  margin: 1rem 2rem;
}

.Page_home .p2 .left_side button{
  margin-bottom: 20px;
}




/* Tablet View */
@media (max-width: 1000px) {
  .Page_home .holder {
    width: 80%;
    
  }





  .Page_home .header .title {
    font-size: 24px;
    margin-left: 1rem;
  }

  .Page_home .header .filters {
    min-width: auto;
    margin-right: 1rem;
  }

  .Page_home .grid {
    grid-template-columns: repeat(2, 1fr);

    padding-left: 0px;
    padding-right: 0px;
  }

  .Page_home .p2 {
    flex-direction: column;
    height: auto;
  }

  .Page_home .p2 .left_side, .Page_home .p2 .right_side {
    width: 100%;
  }

  .Page_home .p2 .left_side .title {
    font-size: 24px;
    margin: 1rem;
  }

  .Page_home .p1 .hotelscards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; /* Add spacing between cards */
    margin-top: 2rem;
  }


  .Page_home .p2 .right_side .logo2{
    display: flex;

  }

  .Page_home .p2 .right_side .logo{
    display: none;
    position:absolute;
    bottom: 20px;
    right: 30px;
  }


  
}





/* Mobile View */
@media (max-width: 480px) {
 
  .Page_home .holder .search {
    flex-direction: column;
    gap: 10px;
    height: 150px;
  }

  .Page_home .holder .search .search_input{
    max-width: 300px;
  }

  .Page_home .holder {
    width: 80%;
    right: 15px;
    height: 150px;
  }


  .Page_home .header .title {
    font-size: 20px;
    margin-left: 0.5rem;
    width: 100%;
    text-align: center;
  }

  .Page_home .header .filters {
    display: none;
  }

  .Page_home .grid {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }

  .Page_home .p2 .left_side .title {
    font-size: 18px;
    margin: 1rem;
  }

  .Page_home .p1 .button button {
    width: 90%;
    
  }

  .Page_home .p2 .left_side, .Page_home .p2 .right_side {
    width: 100%;
  }

  .Page_home .p3 {
    flex-direction: column;
    height: auto;
  }

  .Page_home .p3 .right_part, .Page_home .p3 .left_part {
    width: 100%;
  }
  .Page_home .p1 .hotelscards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px; /* Space between each card */
    margin-top: 2rem;
  }

  .Page_home .p2 .right_side .logo2{
    bottom: 12px;
  }

  .Page_home .p2 .left_side button{
    margin-bottom: 12px;
  }
}

.loading-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: #f0f0f0;
}

.animate-pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* RTL Specific Styles */
.Page_home[dir="rtl"] {
  direction: rtl;
}

.Page_home[dir="rtl"] .holder .search {
  left: auto;
  right: 0;
}

.Page_home[dir="rtl"] .holder .search button {
  margin-left: 0;
  margin-right: 1rem;
}

.Page_home[dir="rtl"] .search_input img {
  left: auto;
  right: 10px;
}

.Page_home[dir="rtl"] .search_input .calendar {
  right: auto;
  left: 0;
}

.Page_home[dir="rtl"] .search_input input {
  padding-left: 0;
  padding-right: 2.5rem;
}

.Page_home[dir="rtl"] .header .title {
  margin-left: 0;
  margin-right: 4rem;
  text-align: right;
}

.Page_home[dir="rtl"] .header .filters {
  margin-right: 0;
  margin-left: 4rem;
}

.Page_home[dir="rtl"] .grid {
  padding-left: 0;
  padding-right: 2rem;
}

.Page_home[dir="rtl"] .p2 .left_side {
  align-items: flex-start;
}

.Page_home[dir="rtl"] .p2 .left_side .title,
.Page_home[dir="rtl"] .p2 .left_side .text {
  margin-left: 0;
  margin-right: 2rem;
  text-align: right;
}

.Page_home[dir="rtl"] .p2 .left_side .text {
  padding-right: 0;
  padding-left: 3rem;
}

.Page_home[dir="rtl"] .p2 .left_side button {
  margin-left: 0;
  margin-right: 2rem;
}

.Page_home[dir="rtl"] .p2 .right_side .logo,
.Page_home[dir="rtl"] .p2 .right_side .logo2 {
  right: auto;
  left: 30px;
}

.Page_home[dir="rtl"] .header .redirect {
  margin-right: 0;
  margin-left: 3rem;
}

.Page_home[dir="rtl"] .header .redirect p {
  margin-right: 0;
  margin-left: 1rem;
}

/* Tablet RTL Adjustments */
@media (max-width: 1000px) {
  .Page_home[dir="rtl"] .header .title {
    margin-left: 0;
    margin-right: 1rem;
  }
  
  .Page_home[dir="rtl"] .header .filters {
    margin-right: 0;
    margin-left: 1rem;
  }
}

/* Mobile RTL Adjustments */
@media (max-width: 480px) {
  .Page_home[dir="rtl"] .header .title {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  
  .Page_home[dir="rtl"] .p2 .left_side .title,
  .Page_home[dir="rtl"] .p2 .left_side .text {
    margin-left: 0;
    margin-right: 1rem;
  }
  
  .Page_home[dir="rtl"] .p2 .left_side button {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Font family for Arabic text */
.Page_home[dir="rtl"] .title,
.Page_home[dir="rtl"] .text,
.Page_home[dir="rtl"] button,
.Page_home[dir="rtl"] input {
  font-family: "Tahoma", "Poppins", sans-serif;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}