.Navbar{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid #B0AEAE;
    background: #FFF;
    direction: ltr !important;
}

.Navbar .logo{
    margin-left: 2rem;
    max-width: 110px;
    height: 56px;
}
.Navbar .logo img{
    width: 100%;
    height: 100%;
}
.Navbar .nav{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
    margin-right: 2rem;
}
.Navbar .nav button {
    height: 50px;
    max-width: 120px ;
    padding: .5rem 1rem;
    background-color: #fff;
    border-radius: 4px;
border: 1px solid #FF6300;
cursor: pointer;


}
.Navbar .nav button{
    color: #FF6300;
text-align: center;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
opacity: .8;
}
.Navbar .nav button:hover{
opacity: 1;
}
.Navbar .nav .elements{
    margin-right: 4rem;
display: flex;
flex-direction: row-reverse;
align-items: center;
justify-content: center;
}

.Navbar .nav .elements .el{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #686868;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
    margin: 0 1rem;
cursor: pointer;
min-width: 80px;
}
.Navbar .nav .elements .el:hover{
    height: 96px;
    border-bottom: 4px solid #239EFE;
    color: #043263;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 100ms ease;
}
.Navbar .nav .elements .el p{
    padding: 0;
    margin: 0;
}
.Navbar .nav .elements .el.selected{
    height: 96px;
    border-bottom: 4px solid #239EFE;
    color: #043263;
    display: flex;
    align-items: center;
    justify-content: center;

}
.Navbar .nav .elements .el.selected p{
    padding-top: 4px;
}

.Navbar .profile{
    width: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.Navbar .profile .nav_profileimg{
    margin: auto .7rem;
    width: 38px;
    height: 38px;
    object-fit: cover;
}

.Navbar .profile .name{
    margin: auto .7rem auto 0;
    color: #686868;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
}

.Navbar .profile .open{
    cursor: pointer;
    animation: rotateUp 1s forwards;
}
.Navbar .profile .closed{
    cursor: pointer;
    animation : rotateDown 1s forwards;
}
@keyframes rotateUp {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

@keyframes rotateDown {
    from{
        transform:rotate(180deg);
    }

    to{
        transform: rotate(0deg);
    }
    
}
  .Navbar .profile .container{
    position: absolute;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    top: 60px;
    left: 0;
    z-index: 999;

  }
  .Navbar .profile .container.open{
    animation: openDown 1s forwards;

  }
  .Navbar .profile .container.closed{
    animation: closeDown 1s forwards;

  }
  .Navbar .profile .container .content{
    border: 1px solid #cccccc;
    width: 100%;
    height: 100%;
    margin: auto .7rem auto 0;
    color: #686868;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
align-items: flex-start;
flex-direction: column;
justify-content: flex-start;
  }

  
.Navbar .profile .container .content .el{
    cursor: pointer;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.Navbar .profile .container .content .el:hover{
    background-color: #cccccc;
    color: #fff !important;
}

.Navbar .profile .container .content .el p{
    margin-left: 1rem;
}

.cover3{
    width: 150px; /* Adjust the size as necessary */
    height: 150px; /* Ensure height is equal to width for a perfect circle */
    border-radius: 50%;
    object-fit: cover; /* Ensures the image is not stretched and keeps its aspect ratio */
    border: 2px solid #ccc; /* Optional: add a border to make the circle stand out */
  }

@keyframes openDown {
    from {
      height: 0;
      opacity: 0;
      transform-origin: top;
      transform: scaleY(0);
    }
    to {
      height: fit-content;
      opacity: 1;
      transform-origin: top;
      transform: scaleY(1);
    }
  }


  @keyframes closeDown {
    from {
        height: fit-content;
        opacity: 1;
        transform-origin: top;
        transform: scaleY(1);

    }
    to {
        height: 0;
        opacity: 0;
        transform-origin: top;
        transform: scaleY(0);
    }
  }

  




  .Navbar .side{
    width: 100%;
    
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
   
}

  .Navbar .logo2 img{
    display: none;
    cursor: pointer;
    
  }



.Navbar .side .burger-menu{
    width: 100%;
    
    
    display: flex;
    flex-direction: column;
    
} 

.Navbar .side .burger-menu .ite{
    width: 100%;
    
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    color: #686868;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    cursor: pointer;
}

.Navbar .side .burger-menu .ite:hover{
    background-color: #239EFE;
    color: #fff !important;
}


.Navbar .hiden{
    display: none;
}

@media (max-width: 910px) {
    .Navbar .nav .elements {
        margin-right: 1rem;
    }
}

@media (max-width: 830px) {




    .Navbar .nav{
    margin-right: 20px;
}



.Navbar .logo{
    margin: 0px;
}


}
  @media (max-width: 768px) {


    .Navbar{
        width: 100%;
        height: 100%;
        display: flex;
        
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.5px solid #B0AEAE;
        background: #fff;
    }

    .Navbar .nav {
        display: none;
    }

    .Navbar .side{
        display: flex;
        
        
    }

    .Navbar .hiden{
        display: none;
    }
    

    .Navbar .logo {
        display: none;
    }


    .Navbar .logo2{
   
        display: flex;
        justify-content: end;
        
        border-bottom: 1px solid #686868;
        padding-left: 0px;
        width: 100%;
        height: 52px;
        
    }
    .Navbar .logo2 img{
        display: flex;
        height: 80%;
        
    
        margin: 5px 15px 0px;
    }

    
  }
  

