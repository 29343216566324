.Coupon{
    margin: 1rem;
    border-radius: 4px;
border: 1px solid #ABABAB;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    width: 30%;
    min-width: 450px;
    height: 220px;
}

.Coupon .row{
width: 90%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
}

.Coupon .row .blue_title{
    color: #043263;
font-family: "Poppins";
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 40px; /* 142.857% */
margin: 0;
}

.Coupon .row .faded{
    color: #727272;
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 22.632px; /* 161.654% */
}

.Coupon .row .blue{
    color: #043263;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: 0.28px;
text-transform: uppercase;
}

.Coupon .row .action{
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.Coupon .row .action img{margin-right: .5rem;}

.Coupon .row .golden{
    color: #FF7A00;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}

.Coupon .row .blue_small{
    color: #043263;
font-family: "Poppins";
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 166.667% */
}

.Coupon ul{
    width: 90%;
    padding-left: 1.3rem;
    margin: 0;
}

.Coupon ul .too_faded{
    padding: 0%;
    margin: 0;

    color: #9D9D9D;
font-family: "Poppins";
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 166.667% */
}