.Checkout_vol {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 2rem 0;
}

.Checkout_vol .main {
  width: 90%;
  max-width: 1400px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
}

.Checkout_vol .main .big_title {
  margin-bottom: 2rem;
  color: #292930;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.Checkout_vol .main .container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  gap: 2rem;
}

.Checkout_vol .main .container .form {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #AEAEAE;
  width: 47%;
  border-radius: 4px;
}

.Checkout_vol .main .container .form form {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.5rem;
}

.Checkout_vol .main .container .form form .title {
  color: #000;
  font-family: "Open" "Sans";
  font-size: 18.105px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.632px;
  margin-bottom: 2rem;
}

/* tow inputs */

.Checkout_vol .main .container .form form div label input:focus{
    outline: none;
    border: 1px solid #239EFE;

}


.Checkout_vol .main .container .form form .two_inputs label .input_label.z{
    z-index: 999 !important;
}
.Checkout_vol .main .container .form form .two_inputs label .input_label{

    padding: 0 .3rem;
    background-color: rgb(248, 252, 255);
    border-radius: 6px;
    position: absolute;
    top: -26px;
    left: 15px;


    color: #828282;
font-family: "Poppins";
font-size: 13.579px;
font-style: normal;
font-weight: 400;
line-height: 22.632px;
}

.Checkout_vol .main .container .form form .two_inputs {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  width: 100%;
}

.Checkout_vol .main .container .form form .two_inputs label {
    width: 45%;
    height: 50px;
  position: relative;
}
.Checkout_vol .main .container .form form .two_inputs label input {
    outline: none;
    padding-left: 1rem;
  border-radius: 4.526px;
  border: 1.132px solid #b2bcca;
  background: #fff;
  width:90%;
  min-width: 150px;
  height: 50px;
}


.Checkout_vol .main .container .form form .two_inputs label input::placeholder{
  color: #c5c5c5;
font-family: "Poppins";
font-size: 16.974px;
font-style: normal;
font-weight: 600;
line-height: 22.632px;
}
.Checkout_vol .main .container .form form input{
  color: #4F4F4F;
  font-family: "Poppins";
  font-size: 16.974px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.632px;
}
/* one input */

.Checkout_vol .main .container .form form .one_input label .input_label{
    padding: 0 .3rem;
    background-color: rgb(248, 252, 255);
    border-radius: 6px;
    position: absolute;
    top: -26px;
    left: 15px;


    color: #828282;
font-family: "Poppins";
font-size: 13.579px;
font-style: normal;
font-weight: 400;
line-height: 22.632px;
}

.Checkout_vol .main .container .form form .one_input {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  width: 100%;
}

.Checkout_vol .main .container .form form .one_input label {
    width: 100%;
    height: 50px;
  position: relative;
}
.Checkout_vol .main .container .form form .one_input label input {
    outline: none;
    padding-left: 1rem;
  border-radius: 4.526px;
  border: 1.132px solid #b2bcca;
  background: #fff;
  width:90%;
  min-width: 250px;
  height: 50px;
}


.Checkout_vol .main .container .form form .one_input label input::placeholder{
  color: #c5c5c5;
font-family: "Poppins";
font-size: 16.974px;
font-style: normal;
font-weight: 600;
line-height: 22.632px;
}

.css-qbdosj-Input{
    margin: 0 !important;
}
.css-qbdosj-Input{
    height: 99% !important;
    padding: 0 !important;
    margin: 0 !important;
}


.Checkout_vol .main .container .form form .check{
  margin: 1.5rem 0;
    width: 100%;
}

.Checkout_vol .main .container .form form .check label{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.Checkout_vol .main .container .form form .check label .text{
  margin-left: .5rem;
    color: #4F4F4F;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

}
.Checkout_vol .main .container .form form .check label input{
  width: 13px;
  height: 13px;
  cursor: pointer;
}
.Checkout_vol .main .container .form form .check label .text a{
    text-decoration: none;
    border: none;
    color: #239EFE;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

}


.Checkout_vol .main .container .facture {
  width: 46%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
}

.Checkout_vol .main .container .facture .content {
  border: 1px solid #AEAEAE;
  padding: 2rem;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.5rem;
}

.Checkout_vol .main .container .facture .image{
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.Checkout_vol .main .container .facture .content .title{
  margin-top: 2rem;
 width: 100%;
  color: #000;
font-family: "Poppins";
font-size: 18.415px;
font-style: normal;
font-weight: 700;
line-height: 23.019px;
}

.Checkout_vol .main .container .facture .content .el{
  margin-bottom: .5rem;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.Checkout_vol .main .container .facture .content .el_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 7rem;
}
.Checkout_vol .main .container .facture .content .el .info{
  padding: 0;
  margin: 0;
  color: #4F4F4F;
font-family: "Poppins";
font-size: 16.113px;
font-style: normal;
font-weight: 400;
line-height: 23.019px;
}

.Checkout_vol .main .container .facture .content .el .price{
  padding: 0;
  margin: 0;
  color: #4F4F4F;
text-align: right;
font-family: "Poppins";
font-size: 17.264px;
font-style: normal;
font-weight: 600;
line-height: 23.019px;
}

.Checkout_vol .main .container .facture .content .el .golden_tag{
  color: #FFA800;
font-family: "Poppins";
font-size: 16.113px;
font-style: normal;
font-weight: 700;
line-height: 23.019px;
}

.Checkout_vol .main .container .facture .content .el input{
  height: 50px;
  padding-left: 1rem;
  width: 30%;
  min-width: 230px;
  border-radius: 4px;
border: 1px solid #DBDBDB;
background: #FFF;
}

.Checkout_vol .main .container .facture .content .el input{
  color: #4F4F4F;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.Checkout_vol .main .container .facture .content .remise{
  width: 90%;
  text-align: right;
  color: #4F4F4F;
font-family: "Poppins";
font-size: 17.264px;
font-style: normal;
font-weight: 600;
line-height: 23.019px;
}
.Checkout_vol .main .container .facture .content .el .total{
  color: #000;
font-family: "Poppins";
font-size: 18.415px;
font-style: normal;
font-weight: 700;
line-height: 23.019px;
}
.Checkout_vol .main .container .facture .content .el .total_price{
  color: #000;
  text-align: right;
  font-family: "Poppins";
  font-size: 17.264px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.019px;
}


.Checkout_vol .main .container .facture .content .el button{
  cursor: pointer;
  min-width: 230px;
  width: 40%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius:5px ;
  border: none;
  opacity: .95;
}
.Checkout_vol .main .container .facture .content .el button:hover{
  opacity: 1;
}

.Checkout_vol .main .container .facture .content .el .payement1{
  min-width: 150px;
  background-color: #239EFE;
}
.Checkout_vol .main .container .facture .content .el .payement2{
  background-color: #a5a5a5;
  min-width: 150px;
}
.Checkout_vol .main .container .facture .content .el button p{
  color: #FFF;
text-align: center;
font-family: "Poppins";
font-size: 13.028px;
font-style: normal;
font-weight: 500;
line-height: 18.612px;
text-transform: capitalize;
}
.Checkout_vol .main .container .facture .content .line{
  width: 90%;
  height: 1px;
  background-color: #E0E0E0;
}
.Checkout_vol .main .container .facture .content .el button img{
  margin: auto .5rem auto 0;
}










.Checkout_vol .main .container .form form .two_inputs label .css-1hb7zxy-IndicatorsContainer{
  position: absolute;
  right: 10px;
  top: 5px;
}
.Checkout_vol .main .container .form form .two_inputs label .css-b62m3t-container{
  width: 100%;
}


/* Responsive styles */
@media (max-width: 1024px) {
  .Checkout_vol .main .container {
    flex-direction: column;
    gap: 2rem;
  }

  .Checkout_vol .main .container .form,
  .Checkout_vol .main .container .facture {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .Checkout_vol {
    padding: 1rem;
  }

  .Checkout_vol .main .big_title {
    font-size: 24px;
    margin-bottom: 1.5rem;
  }

  .Checkout_vol .main .container .form,
  .Checkout_vol .main .container .facture {
    padding: 1rem;
  }

  .Checkout_vol .main .container .facture .content {
    padding: 1rem;
    width: 90%;
  }

  .Checkout_vol .main .container .facture .content .el {
    flex-direction: column;
    gap: 0.5rem;
    align-items: stretch;
    width: 100%;
  }

  .Checkout_vol .main .container .facture .content .el button {
    width: 100%;
  }

  .Checkout_vol .main .container .facture .content .el .price{
    text-align: center;
  }

  .Checkout_vol .main .container .facture .content .el .total_price{
    text-align: center;
  }
}

@media (max-width: 480px) {
  .Checkout_vol .main {
    width: 95%;
  }

  .Checkout_vol .main .container .form form .two_inputs {
    flex-direction: column;
    gap: 1.5rem;
  }

  .Checkout_vol .main .container .form form .two_inputs label {
    width: 100%;
  }

  .Checkout_vol .main .container .form form .check label .text {
    font-size: 12px;
  }
}

.error_badge {
  color: red;
  font-size: 0.85rem;
  margin-top: 0.25rem;
  display: block;
}



