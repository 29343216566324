.images_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.images_container .main_img{
    border-radius: 4px;
    width: 100%;
    height: 70%;
    object-fit: cover;
}


.images_container .images{
    width: 100%;
    height: 23%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.images_container .images .sub_img{
    margin: 0 1.5%;
    object-fit: cover;
    width: 30%;
    height: 100%;
    border-radius: 4px;
}


.images_container .images .more_images{
    position: relative;
    margin: 0 1.5%;
    padding: 0;
    width: 30%;
    height: 100%;
    border-radius: 4px;
}
.images_container .images .more_images .sub_img{
    padding: 0%;
    
    object-fit: cover;
    width: 100%;
    height: 100%;

}


.images_container .images .more_images .more{

    left: 1.5%;
    border-radius: 4px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000000B0;


}

.images_container .images .more_images .more p{
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-family: "Poppins";
    font-size: 40px;
    font-weight: 700;
    line-height: 61px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #FFFFFF9C;
    
}