.demande-section {
  padding: 20px 50px;
  background: linear-gradient(135deg, #f9f9f9 0%, #fff 100%);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 30px auto;
  text-align: start;
  width: 90%;
  max-width: 90%; /* Ensure it fits smaller screens */
}

.demande-section h2 {
  color: #ff6600;
  font-size: 1.8em;
  margin-bottom: 20px;
}

.explanatory-text {
  font-size: 1em;
  color: #666;
  margin-bottom: 30px;
}

.input-group-container {
  display: flex;
  gap: 20px;
  margin-bottom: 50px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  justify-content: space-between; /* Adjust layout for small screens */
}

.input-group {
  font-size: 1.1em;
  color: #555;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  min-width: 120px; /* Ensure proper width on very small screens */
}

.input-group label {
  margin-bottom: 10px;
}

.input-group select {
  padding: 8px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%; /* Adjust width for responsiveness */
  max-width: 180px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allows buttons to stack on small screens */
  gap: 10px; /* Adds space between buttons */
}

.button-group button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #ff6600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 120px; /* Ensures button is clickable on small screens */
}

.button-group > .retour {
  background-color: #afafaf;
}

.button-group button:hover {
  background-color: #e05500;
}

.button-group > .retour:hover {
  background-color: #999;
}

/* Responsive styles */
@media (max-width: 856px) {
  .demande-section {
    width: 70%; /* Reduce width for smaller screens */
  }

  .input-group-container {
    flex-direction: column; /* Stack inputs vertically */
    gap: 15px;
  }

  .input-group {
    width: 100%; /* Full width for inputs */
    align-items: stretch; /* Align inputs properly */
  }

  .input-group select {
    max-width: 100%; /* Ensure full-width select boxes */
  }

  .button-group {
    justify-content: center; /* Center buttons on smaller screens */
    gap: 15px;
  }

  .button-group button {
    width: 100%; /* Full-width buttons */
    max-width: 200px; /* Prevent overly large buttons */
  }
}

@media (max-width: 480px) {
  .demande-section {
    padding: 20px; /* Reduce padding on very small screens */
  }

  .demande-section h2 {
    font-size: 1.5em; /* Smaller font size for headings */
  }

  .explanatory-text {
    font-size: 0.9em;
  }

  .button-group button {
    font-size: 0.9em; /* Adjust button text size */
  }
}


/* Arabic (RTL) Version - Class Selector Only */
.demande-section.rtl {
  font-family: "Tahoma", "Arial", sans-serif;
  text-align: right;
  direction: rtl;
}

.demande-section.rtl h2 {
  font-family: "Tahoma", "Arial", sans-serif;
}

.demande-section.rtl .explanatory-text {
  text-align: right;
  unicode-bidi: plaintext;
}

.demande-section.rtl .input-group-container {
  justify-content: flex-end;
}

.demande-section.rtl .input-group {
  align-items: flex-start;
}

.demande-section.rtl .input-group label {
  margin-right: 0;
  margin-left: 10px;
}

.demande-section.rtl .button-group {
  flex-direction: row-reverse;
}

/* Responsive adjustments for RTL */
@media (max-width: 856px) {
  .demande-section.rtl .input-group-container {
    align-items: flex-end;
  }
  
  .demande-section.rtl .input-group {
    align-items: flex-end;
  }
}

@media (max-width: 480px) {
  .demande-section.rtl .button-group {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  
  .demande-section.rtl .button-group button {
    text-align: right;
  }
}