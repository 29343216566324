
.description-section {
  font-family: Arial, sans-serif;
  padding: 30px 50px;
  background: linear-gradient(135deg, #f9f9f9 0%, #fff 100%);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-height: 400px;
  margin-top: 30px;
  margin-bottom: 70px;
  transition: box-shadow 0.3s, transform 0.2s;
  text-align: start;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);

  width: 70%;
}



.description-section h2 {
  color: #ff6600;
  font-size: 1.8em;
  margin-bottom: 40px;

}

.description-text {
  font-size: 1.1em;
  color: #555;
  line-height: 1.8;
  text-align: justify;
  width: 100%;
  margin: 0 auto;
}



/* Arabic (RTL) Version - Class Selector Only */
.description-section.rtl {
  font-family: "Tahoma", "Arial", sans-serif;
  text-align: right;
  direction: rtl;
}

.description-section.rtl h2 {
  font-family: "Tahoma", "Arial", sans-serif;
  color: #ff6600;
  margin-bottom: 40px;
}

.description-section.rtl .description-text {
  font-family: "Tahoma", "Arial", sans-serif;
  text-align: right;
  direction: rtl;
  unicode-bidi: embed;
  line-height: 1.8;
  letter-spacing: 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .description-section.rtl {
    padding: 20px 30px;
    width: 85%;
  }
  
  .description-section.rtl .description-text {
    font-size: 1em;
  }
}

/* Hover effects maintained */
.description-section.rtl:hover {
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.2);
}