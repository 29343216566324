.Comptinformations{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.Comptinformations .title{
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
}


.Comptinformations .title p{
    color: #000;
font-family: "Open Sans";
font-size: 18.105px;
font-style: normal;
font-weight: 700;
line-height: 22.632px; /* 125% */
}

.Comptinformations .title img{
    cursor: pointer;
    opacity: .8;
}
.Comptinformations .title img:hover{
    opacity: 1;
}


.Comptinformations .two_inputs label .input_label.z{
    z-index: 999 !important;
}
.Comptinformations .two_inputs label .input_label{

    padding: 0 .3rem;
    background-color: #fff;
    position: absolute;
    top: -26px;
    left: 15px;


    color: #828282;
font-family: "Poppins";
font-size: 13.579px;
font-style: normal;
font-weight: 400;
line-height: 22.632px;
}

.Comptinformations .two_inputs {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  width: 95%;
}

.Comptinformations .two_inputs label {
    width: 44%;
    height: 50px;
  position: relative;
}


.Comptinformations .two_inputs label input {
    outline: none;
    padding-left: 1rem;
  border-radius: 4.526px;
  border: 1.132px solid #b2bcca;
  background: #fff;
  width:96%;
  min-width: 70px;
  height: 50px;
}




.Comptinformations .two_inputs label input::placeholder{
    color: #4F4F4F;
font-family: "Poppins";
font-size: 16.974px;
font-style: normal;
font-weight: 600;
line-height: 22.632px;
}

.Comptinformations .two_inputs .resetpsw{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 47%;
    height: 50px;
    border-radius: 4px;
background: rgba(35, 158, 254, 0.07);
}
.Comptinformations .two_inputs .resetpsw p{
    margin-left: 1rem;
    color: #239EFE;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
border: none;

}
.Comptinformations .two_inputs .resetpsw p a{
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid #239EFE ;

    color: #239EFE;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
}
.Comptinformations .two_inputs label .css-1hb7zxy-IndicatorsContainer{
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .Comptinformations .two_inputs label .css-b62m3t-container{
    width: 96%;
  }

#riwriw{
    outline: none;
    padding-left: 1rem;
  border-radius: 4.526px;
  border: 0px solid #b2bcca;
  background: #fff;
  width:96%;
  min-width: 70px;
  height: 50px;
  background: #f4f4f4;
  }

  #awtawt{
    background: #FF6300;; /* Change background to orange on hover */
    color: white; /* Change text to white on hover */
    min-width: 200px;
    text-align: center;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
  }

  #awtawt:hover{
    background: orange;
  }

  @media (max-width: 768px) {
    .Comptinformations .two_inputs {
        flex-direction: column;
        align-items: stretch;
    }

    .Comptinformations .two_inputs label {
        width: 100%;
        margin-bottom: 1rem;
    }

    #awtawt {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .Comptinformations .two_inputs label .input_label {
        font-size: 12px;
    }

    .Comptinformations .two_inputs label input {
        font-size: 14px;
    }

    #awtawt {
        font-size: 14px;
        padding: 10px 20px;
    }
}


  