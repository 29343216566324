.page-reservation {
    text-align: center;
  }

  .page-reservation > .forehead{
    align-items: start;
    
  }
  
  .forehead-contenta {
    padding: 20px;
    width: 100%;
  }
  
  .breadcrumb {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .breadcrumb a {
    text-decoration: none;
    color: #333;
  }
  
  .breadcrumb-separator {
    margin: 0 5px;
  }
  
  .forehead-title h2 {
    font-size: 24px;
    margin: 10px 0;
    color: #333;
  }
  
  .step-indicator {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin-top: 10px;
  }
  
  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #333;
    
    transition: background-color 0.3s, color 0.3s;
  }
  
  .circle.active {
    background-color: #ff6600;
    color: #fff;
  }
  
  .step-content {
    padding: 5px 20px 70px 20px;
    font-size: 18px;
    color: #333;
    

    min-height: 400px;

    display: flex;
    justify-content: center;
    
  }

  .step-indicator div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .step-indicator p {
    font-size: 18px;
    color: white;
    margin-top: 8px;
    font-weight: 500;
    text-align: center;
    text-shadow: 2px 2px 5px rgb(0, 0, 0);
  }
  
