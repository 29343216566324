.occupation-section {
    padding: 30px 50px;
    background: linear-gradient(135deg, #f9f9f9 0%, #fff 100%);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 30px;
    text-align: center;
  }
  
  .occupation-section h2 {
    color: #ff6600;
    font-size: 1.8em;
    margin-bottom: 20px;
  }
  
  .occupation-section p {
    font-size: 1.1em;
    color: #555;
  }
  