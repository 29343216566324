.Footer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    background-color: #1C1C1F;
    padding: 5rem 0 3rem 0;
    direction: ltr !important;
}

.Footer .list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.Footer .list p {
    margin: 0 0 1rem 0;
}

.Footer .list.var1 {
    margin-left: 10%;
}

.Footer .list.var2 {
    margin-right: 10%;
}

.Footer .list.var3 {
    align-items: center;
    justify-content: center;
}

.Footer .list .sub_el {
    margin-top: 2rem;
}

.Footer .list .sub_el {
    color: #FFF;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}

.Footer .list .title {
    color: #FFF;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
}

.Footer .list .el {
    color: #FFF;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;
}

.Footer .list img {
    margin-bottom: 3rem;
}

/* Tablet View */
@media (max-width: 768px) {
    .Footer {
        flex-direction: column;
        align-items: center;
        padding: 4rem 0rem 3rem 0rem;
    }

    .Footer .list {
        align-items: center;
        text-align: center;
        margin: 2rem 0;
    }

    .Footer .list.var1, 
    .Footer .list.var2 {
        margin-left: 0;
        margin-right: 0;
    }

    .Footer .list .title {
        font-size: 15px;
    }

    .Footer .list .el {
        font-size: 13px;
    }

    .Footer .list .sub_el {
        font-size: 11px;
    }
}

/* Mobile View */
@media (max-width: 480px) {
    .Footer {
        padding: 0px;
    }

    .Footer .list .title {
        font-size: 14px;
    }

    .Footer .list .el,
    .Footer .list .sub_el {
        font-size: 12px;
    }

    .Footer .list p {
        margin-bottom: 0.75rem;
    }

    .Footer .list img {
        max-width: 150px; /* Adjust logo size for mobile */
    }
}
