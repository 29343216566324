.Page_notfound{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Page_notfound .main{
    padding: 10rem 0;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Page_notfound .main .container{
    z-index: 999;
    padding: 4rem 2rem;
    background: #043263;
    display: flex;
border-radius: 60% 60% 0 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Page_notfound .main .container .big_404{
    color: #FFF;
font-family: "Poppins";
font-size: 107.333px;
font-style: normal;
font-weight: 900;
line-height: 149%; /* 159.927px */
letter-spacing: 10.733px;
text-transform: uppercase;
margin-bottom: 2rem;

}

.Page_notfound .main .container .blue_text{
    color: #239EFE;
    font-family: "Poppins";
    font-size: 34.649px;
    font-style: normal;
    font-weight: 700;
    line-height: 149%; /* 51.627px */
    letter-spacing: 3.465px;
    text-transform: uppercase;
}

.Page_notfound .main .container .white_text{
    color: #FFF;
text-align: center;
font-family: "Poppins";
font-size: 27.644px;
font-style: normal;
font-weight: 500;
line-height: 149%; /* 41.189px */
letter-spacing: 0.138px;
}

.Page_notfound .main .s1{
    position: absolute;
    right:30% ;
    top: 20%;
}
.Page_notfound .main .s2{
    position: absolute;
    right:50% ;
    top:85%;
}

.Page_notfound .main .c1{
    position: absolute;
    right:30% ;
    top:70%;
}
.Page_notfound .main .c2{
    position: absolute;
    left:30% ;
    top:35%;
}

.Page_notfound .main .pstructure{
    z-index: 0;
    position: absolute;
    left:20% ;
    top:20%;
}