/* .carousel{
    border: 1px solid black;
    width: 500px;
    height: 500px;
}

.swiper-container-vertical {
    height: 100%;
  }
  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .swiper-pagination {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: rotate(-90deg);
  }
  
  .swiper-pagination-bullet {
    margin-right: 10px;
  }
  
  .swiper-pagination-bullet:not(:last-child) {
    margin-right: 20px;
  }
  
  .swiper-pagination-bullet-active {
    transform: translate(-50%, -50%);
  }
   */

   
   


   .slide_cn {
    width: 100%;
    height: 100%;
}

.custom-swiper {
    width: 100%;
    height: 425px;
}

.img_text {
    position: absolute;
    width: 100%;
    text-align: center;
    color: white;
    left: 0;
    height: 100px;
    overflow: hidden;
}

.img_title {
  
    color: #FFF;
    font-family: "Poppins";
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 1px;
    top:-5px;
}

.iteo {
    display: flex;
    justify-content: center;
    width: 60%;
}

.img_disc {
    top: 110px;
    color: #FFF;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 157%;
    letter-spacing: 0.24px;
    width: 60%;
    margin-left: 20%;
}

.voir_offres {
    position: absolute;
    top: 270px;
    left: 50%;
    transform: translateX(-50%);
    color: #FFF;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: rgba(35, 158, 254, 0.9);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 24px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 200px;
    height: 50px;
}

.voir_offres:hover {
    background-color: rgba(35, 158, 254, 1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transform: translateX(-50%) translateY(-2px);
}

.voir_offres:active {
    transform: translateX(-50%) translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.voir_offres:focus {
    outline: none;
}

.img_price {
    color: #FFF;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 2.52px;
    top: 290px;
}

.img_duration {
    color: #FFF;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 186%;
    letter-spacing: -0.4px;
    top: 350px;
}

.blue_text {
    color: #239EFE;
    text-transform: uppercase;
    font-size: 42px;
}

.swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    border-radius: 4px !important;
    border: 1px solid white !important;
}

.swiper-pagination-bullet-active {
    width: 11px !important;
    height: 11px !important;
    border-radius: 4px !important;
    background-color: #FF6300 !important;
    border: none !important;
}

.img_title, .img_disc, .img_price, .img_duration {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* Tablet View */
@media (max-width: 768px) {
    .img_title {
        font-size: 32px;
        top: 14%;
        transform: translateY(-80%);
    }

    .img_disc {
        font-size: 18px;
        top: 44%;
        transform: translateY(-70%);
        margin-left: 20px;
        width: 94%;
        height: 140px;
        overflow: hidden;
    }

    .voir_offres {
        top: 280px;
        font-size: 16px;
        padding: 10px 20px;
    }

    .img_price {
        font-size: 22px;
        top: 60%;
        transform: translateY(-60%);
    }

    .img_duration {
        font-size: 18px;
        top: 70%;
        transform: translateY(-50%);
    }

    .blue_text {
        font-size: 36px;
    }

    .swiper-pagination {
        left: -10px;
    }
    
    .swiper-pagination-bullet {
        width: 8px !important;
        height: 8px !important;
    }
    
    .swiper-pagination-bullet-active {
        width: 9px !important;
        height: 9px !important;
    }
}

/* Mobile View */
@media (max-width: 480px) {
    .custom-swiper {
        height: 400px;
    }

    .img_title {
        font-size: 26px;
        top: 15%;
        transform: translateY(-70%);
    }

    .img_disc {
        font-size: 16px;
        top: 40%;
        transform: translateY(-60%);
        margin-left: 0px;
        width: 94%;
        height: 125px;
        overflow: hidden;
    }

    .voir_offres {
        top: 250px;
        font-size: 14px;
        padding: 8px 16px;
    }

    .img_price {
        font-size: 20px;
        top: 53%;
        transform: translateY(-50%);
    }

    .img_duration {
        font-size: 16px;
        top: 68%;
        transform: translateY(-40%);
    }

    .blue_text {
        font-size: 30px;
    }

    .swiper-pagination {
        left: -5px;
    }

    .swiper-pagination-bullet {
        width: 6px !important;
        height: 6px !important;
    }
    
    .swiper-pagination-bullet-active {
        width: 7px !important;
        height: 7px !important;
    }
    
    .slide_cn {
        height: 100%;
    }

    .img_text {
        text-align: center;
        padding: 0 10px;
        width: 97%;
    }
    .voir_offres{
        width: 40%;
        height: 60px;
    }
}

.carousel-loading .loading-placeholder {
    display: flex;
    flex-direction: column;
    height: 100%; /* Match your carousel height */
  }
  
  .carousel-loading .image-placeholder {
    height: 100%;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .carousel-loading .content-placeholder div {
    margin-bottom: 8px;
  }
  
  .animate-pulse {
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }