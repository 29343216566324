.Profileinformations{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.Profileinformations .title{
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
}


.Profileinformations .title p{
    color: #000;
font-family: "Open Sans";
font-size: 18.105px;
font-style: normal;
font-weight: 700;
line-height: 22.632px; /* 125% */
}

.Profileinformations .title img{
    cursor: pointer;
    opacity: .8;
}
.Profileinformations .title img:hover{
    opacity: 1;
}


.Profileinformations .two_inputs label .input_label.z{
    z-index: 999 !important;
}
.Profileinformations .two_inputs label .input_label{

    padding: 0 .3rem;
    background-color: #fff;
    position: absolute;
    top: -26px;
    left: 15px;


    color: #828282;
font-family: "Poppins";
font-size: 13.579px;
font-style: normal;
font-weight: 400;
line-height: 22.632px;
}

.Profileinformations .two_inputs {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  width: 95%;
}

.Profileinformations .two_inputs label {
    width: 44%;
    height: 50px;
  position: relative;
}
.Profileinformations .two_inputs label input {


    outline: none;
    padding-left: 1rem;
  border-radius: 4.526px;
  border: 1.132px solid #b2bcca;
  background: #fff;
  width:96%;
  min-width: 70px;
  height: 50px;
  
}


.Profileinformations .two_inputs label input::placeholder{
    color: #4F4F4F;
font-family: "Poppins";
font-size: 16.974px;
font-style: normal;
font-weight: 600;
line-height: 22.632px;
}



.Profileinformations .two_inputs label .css-1hb7zxy-IndicatorsContainer{
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .Profileinformations .two_inputs label .css-b62m3t-container{
    width: 96%;
  }

  #awtawt{
    background-color: white; /* Background color */
    color: black; /* Text color */
    border: 0px solid orange; /* Orange border */
    padding: 12px 24px; /* Adjust the padding to make it bigger */
    font-size: 16px; /* Increase the font size */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 4px; /* Slightly rounded corners */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition on hover */
    margin-top: 20px; /* Add margin to space out */
  }

  #awtawt{
    background: #FF6300; /* Change background to orange on hover */
    color: white; /* Change text to white on hover */
    min-width: 200px;
    text-align: center;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
  }

  #awtawt:hover{
    background: orange; /* Change background to orange on hover */
    
  }

  @media (max-width: 768px) {
    .Profileinformations {
      width: 90%;
      padding: 0 1rem;
    }
    .Profileinformations .two_inputs {
      flex-direction: column;
      align-items: stretch;
    }
    .Profileinformations .two_inputs label {
      width: 100%;
      margin-bottom: 1rem;
    }
    .Profileinformations .title {
      flex-direction: column;
      align-items: flex-start;
    }
    #awtawt {
      width: 100%;
      text-align: center;
    }
  }
  
  @media (max-width: 480px) {
    .Profileinformations .two_inputs label .input_label {
      font-size: 12px;
    }
    .Profileinformations .two_inputs label input {
      font-size: 14px;
    }
    #awtawt {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
  