.Partners {
  width: 95%;
  height: 478px;
  background-color: #F8F8F8;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 4rem;
}

/* RTL specific styles */
.Partners[dir="rtl"] {
  direction: rtl;
}

.Partners[dir="rtl"] .left_part {
  padding: 0 2rem 0 0; /* Adjust padding for RTL */
}

.Partners[dir="rtl"] .left_part .informations {
  margin-left: 0;
  margin-right: 1rem; /* Flip margin for RTL */
}

.Partners[dir="rtl"] .left_part .logos {
  justify-content: flex-end; /* Align logos to the right in RTL */
}

.Partners .right_part {
  width: 40%;
  height: 100%;
}

.Partners .right_part img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Partners .left_part {
  height: 100%;
  width: 60%;
  padding: 0 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.Partners .left_part .informations { 
  margin-left: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.Partners .left_part .informations .title {
  margin: 1rem 0 .5rem 0;
  color: #696363;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-align: start; /* Use start instead of left */
}

.Partners .left_part .informations .disc {
  color: #696363;
  text-align: justify;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 149%;
  letter-spacing: 0.64px;
}

.Partners .left_part .logos {
  width: 90%;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.Partners .left_part .logo {
  margin: 1rem 2rem;
}

/* Tablet View */
@media (max-width: 1085px) {
  .Partners {
    flex-direction: column;
    height: auto;
    padding: 1rem;
  }

  .Partners[dir="rtl"] {
    flex-direction: column-reverse; /* Reverse order for RTL */
  }

  .Partners .right_part {
    width: 100%;
    height: 200px;
  }

  .Partners .left_part {
    width: 100%;
    padding: 1rem;
  }

  .Partners .left_part .informations .title {
    font-size: 24px;
  }

  .Partners .left_part .informations .disc {
    font-size: 14px;
  }

  .Partners .left_part .logos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 2rem;
    margin-left: 65px;
  }

  .Partners[dir="rtl"] .left_part .logos {
    margin-left: 0;
    margin-right: 65px; /* Adjust for RTL */
  }

  .Partners .left_part .logo {
    margin: 0.5rem;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Mobile View */
@media (max-width: 588px) {
  .Partners {
    flex-direction: column;
    height: auto;
    padding: 0px;
    margin-bottom: 4rem;
  }

  .Partners[dir="rtl"] {
    flex-direction: column-reverse; /* Reverse order for RTL */
  }

  .Partners .right_part {
    width: 100%;
    height: 150px;
  }

  .Partners .right_part img {
    object-fit: cover;
    height: 140%;
  }

  .Partners .left_part {
    width: 100%;
    padding: 0.5rem;
  }

  .Partners .left_part .informations .title {
    font-size: 20px;
    margin: 1rem 0 0.5rem 0;
  }

  .Partners .left_part .informations .disc {
    font-size: 14px;
    margin: 0 0 1rem 0;
  }

  .Partners .left_part .logos {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 1rem;
    margin-left: 0px;
  }

  .Partners[dir="rtl"] .left_part .logos {
    margin-right: 0px;
  }

  .Partners .left_part .logo {
    margin: 0.5rem auto;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}