.page-voyageorg {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  background-color: #F8FCFF;
}

.voyageorg-content {
  width: 98%;
  padding: 20px;
}

.forehead {
  height: 200px;
  background-image: url("./imageca.jpg");
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
  display: flex;
  align-items: end;
  justify-content: center;
}

.forehead-content {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0px 20px 20px 20px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forehead-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.forehead-title h2 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  color: #333;
}

.availability-badge {
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.availability-badge.available {
  background-color: green;
  color: white;
}

.availability-badge.not-available {
  background-color: red;
  color: white;
}

.forehead-details {
  display: flex;
  justify-content: start;
  margin-top: 20px;
  width: 100%;
  gap: 40px;
}

.forehead-detail {
  display: flex;
  align-items: center;
  
}

.forehead-detail p {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  color: #333;
  margin-left: 10px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
}

.tab {
  cursor: pointer;
  text-align: center;
  transition: color 0.3s ease;
  color: #6d6d6d;
  font-family: "Poppins", sans-serif;
  display: flex;
  
  align-items: center;
  padding: 10px 0;
}

.tab p {
  margin: 0;
  font-size: 1em;
  font-weight: bold;
}

.tab.selected {
  color: #ffa500; /* Orange color */
  border-bottom: 3px solid #ffa500;
}

.tab:hover {
  color: #ffa500;
  border-bottom: 3px solid #ffa500;
}

.tab img {
  width: 20px;
  height: 20px;
  margin-bottom: 5px; /* Space between icon and text */
  margin-right: 10px;
  margin-bottom: 20px;
}

.tab-content {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.tab-content > div {
  animation: fadeIn 0.3s ease-in-out;
}

.breadcrumb {
  font-size: 0.9rem;
  color: #6d6d6d;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 10px;
  width: 100%;
}

.breadcrumb span {
  color: #ffa500; /* Orange color for the current page */
  font-weight: bold;
}

.breadcrumb a {
  text-decoration: none;
  color: #6d6d6d;
}

.breadcrumb a:hover {
  color: #ffa500;
}

.breadcrumb-separator {
  margin: 0 5px;
  color: #ccc;
}

.forehead-rt{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rat {
  height: 50px;
  width: 300px;
  background-image: url("../../assets/images/icons/rating.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; /* Adjust this to 'cover' if the image is large and you want to fill the element */
}




@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



@media (max-width: 767px) {
  .voyageorg-content {
    width: 100%;
    padding: 10px;
  }

  .forehead {
    height: auto;
    min-height: 250px;
    padding: 10px;
  }

  .forehead-content {
    padding: 15px;
    margin: 10px;
    border-radius: 8px;
  }

  .breadcrumb {
    font-size: 0.8rem;
    margin-bottom: 15px;
  }

  .forehead-title {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .forehead-title h2 {
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
  }

  .forehead-rt {
    flex-direction: column;
    gap: 15px;

    align-items: center;
    
    
  }

  .forehead-details {
    flex-direction: column;
    gap: 15px;
    align-items: center;
    
  }

  .forehead-detail {
    width: 40%;
    justify-items: center;
  }

  .forehead-detail img {
    width: 24px;
    height: 24px;
  }

  .forehead-detail p {
    font-size: 0.9rem;
  }

  .rat-dad {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }

  .rat {
    height: 50px;
    width: 300px;
    background-image: url("../../assets/images/icons/rating.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; /* Adjust this to 'cover' if the image is large and you want to fill the element */
  }

  /* Existing mobile styles for tabs section */
  .tabs {
    flex-direction: column;
    gap: 15px;
    border-bottom: none;
    padding: 10px;
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }

  .tab img {
    width: 18px;
    height: 18px;
    margin: 0;
  }

  .tab p {
    font-size: 0.9rem;
    margin: 0;
  }

  .tab.selected {
    background-color: #ffa500;
    color: #fff;
    border-color: #ffa500;
  }
}

@media (max-width: 325px) {

  .forehead-detail {
    width: 100%;
    
  }
}