.Page_login{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Page_login .main{
    display: flex;
    align-items: row;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    min-height: 910px;
}

.Page_login .main .left_part{
    background-image: url("../../assets/images/images/login.webp");
    background-position:center ;
    background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
    min-height: 910px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Page_login .main .right_part{
    background-color: #fff;
    width: 50%;
    height: 100%;
    min-height: 910px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Page_login .main .right_part .title{
    color: #000;
font-family: "Poppins";
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 50% */
}
.Page_login .main .right_part form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}
.Page_login .main .right_part label p{
    color: #4F4F50;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
text-transform: capitalize;
}
.Page_login .main .right_part label{
    margin-bottom: 2rem;
    width: 45%;
}
.Page_login .main .right_part label input{
    border-radius: 4px;
border: 1px solid #E0E1E4;
background: #FFF;
min-width: 250px;
width: 100%;
height: 45px;
color: #8D8E90;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
padding-left: 1rem;
}


.Page_login .main .right_part label .password{
    width: 100%;
    position: relative;
}

.Page_login .main .right_part label .password img{
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 0;
}

.Page_login .main .right_part form .row{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 45%;
}

.Page_login .main .right_part form .row .check{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;


    color: #8D8E90;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}
.Page_login .main .right_part form .row .check input{
    cursor: pointer;
    margin-right: 1rem;
}
.Page_login .main .right_part form .row .blue a{
    cursor: pointer;
    text-decoration: none;
    border: none;

    color: #239EFE;
font-family: "Poppins";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
}

.Page_login .main .right_part form button{
    cursor: pointer;
    margin: 2rem 0 3rem 0;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #239EFE;
    border: none;
    width: 45%;
    min-width: 200px;
    
    color: #fff;
text-align: center;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
    
}

.Page_login .main .line{
    width: 40%;
    height: 2px;
    background: #9C9C9C;
}

.Page_login .main .register{
    margin: 4rem 0 ;
    color: #4F4F50;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}
.Page_login .main .register a{
    cursor: pointer;
    text-decoration: none;
    border: none;
    color: #239EFE;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}


.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .error-message {
    color: red;
    position: absolute;
    margin-top: 50px;
    font-size: 12px;
  }
  


  
  /* Media query for mobile */
  @media (max-width: 768px) {
    .Page_login .main {
      flex-direction: column;
    }
  
    .Page_login .main .left_part {
      
     display: none;
    }
  
    .Page_login .main .right_part {
      width: 100%;
      padding: 2rem;
      min-height: 100vh; /* Adjust to fit the screen */
    }
  
    .Page_login .main .right_part .title {
      font-size: 32px;
    }
  
    .Page_login .main .right_part form {
      width: 100%;
    }
  
    .Page_login .main .right_part label {
      width: 80%;
    }
  
    .Page_login .main .right_part form .row {
      width: 80%;
    }
  
    .Page_login .main .right_part form button {
      width: 80%;
    }
  
    .Page_login .main .line {
      width: 60%;
    }
  
    .Page_login .main .register {
      font-size: 12px;
    }

    .Page_login .main .right_part label .password img{
        display: none;
    }

    .Page_login .main .right_part{
      width: 100%;
    }

    

    #yuki{
      width: 100%;
    }

    #lefto{
        display: none;
    }

    
  }  
  

  
.Page_login .main .home-link {
  color: #239EFE;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: color 0.2s ease;
}