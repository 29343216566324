body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* @font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Black.ttf") ,
  url("./assets/fonts/Poppins/Poppins-BlackItalic.ttf") ,
  url("./assets/fonts/Poppins/Poppins-Bold.ttf") ,
  url("./assets/fonts/Poppins/Poppins-BoldItalic.ttf") ,
  url("./assets/fonts/Poppins/Poppins-ExtraBold.ttf") ,
  url("./assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf") ,
  url("./assets/fonts/Poppins/Poppins-ExtraLight.ttf") ,
  url("./assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf") ,
  url("./assets/fonts/Poppins/Poppins-Italic.ttf") ,
  url("./assets/fonts/Poppins/Poppins-Light.ttf") ,
  url("./assets/fonts/Poppins/Poppins-LightItalic.ttf") ,
  url("./assets/fonts/Poppins/Poppins-Medium.ttf") ,
  url("./assets/fonts/Poppins/Poppins-MediumItalic.ttf") ,
  url("./assets/fonts/Poppins/Poppins-Regular.ttf") ,
  url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") ,
  url("./assets/fonts/Poppins/Poppins-Thin.ttf") 
  } */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  width: 0px;
  padding: 0px;
  margin: 0px;
  margin-top: 0px;
}


