.Voyagedetails{

  width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}


.Voyagedetails .main{
  border: 1px solid green;
    width: 100%;
    background: #F8FCFF; 
        display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Voyagedetails .main .container{
    margin-top: 2rem;
    width: 90%;
    height:fit-content;
}

.Voyagedetails .main .container .top_section{
    height: 600px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}


.Voyagedetails .main .container .top_section .right_part{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 60%;
    height: 100%;
}



.Voyagedetails .main .container .top_section .right_part .row .input.var1{
    width: 450px;

  }
  .Voyagedetails .main .container .top_section .right_part .row .input.var2{
    width: 450px;
  }
  .Voyagedetails .main .container .top_section .right_part .row .input{
    
    cursor: pointer;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    background: #FFF;    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
    padding: 2px;
    
  }
  .Voyagedetails .main .container .top_section .right_part .row .input img{
    margin:auto .7rem;
  }
  .Voyagedetails .main .container .top_section .right_part .row .input input{
    color: #B6B6B6;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 152%;
letter-spacing: 0.7px;
    width: 100%;
    height: 100%;
    border: none;
  }
  .Voyagedetails .main .container .top_section .right_part .row .input input:focus{
    outline: none;
  }





  .Voyagedetails .main .container .top_section .right_part .row .input1 {
    cursor: pointer;
    width: 450px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    opacity: 0.95;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    background: #fff;
    position: relative;
  }
  
  .Voyagedetails .main .container .top_section .right_part .row .input1 .personnes_input {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    opacity: 0.95;
  }
  .Voyagedetails .main .container .top_section .right_part .row .input1 .personnes_input:hover {
    opacity: 1;
  }
  .Voyagedetails .main .container .top_section .right_part .row .input1 .personnes_input .icon {
    margin: auto 0.7rem;
  }
  .Voyagedetails .main .container .top_section .right_part .row .input1 .personnes_input .click_icon {
    margin: auto 0.7rem auto auto;
  }
  
  .Voyagedetails .main .container .top_section .right_part .row .input1 .personnes_input .placeholder {
    color: #b6b6b6;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.7px;
  }
  
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div {
    position: absolute;
    bottom: -150px;
    overflow: hidden;
    height: 150px;
    animation: slideDown 0.3s ease-out;
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 999;
    background-color: white;
  }
  
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes slideUp {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-300px);
    }
  }
  
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div.expanded {
    animation: slideUp 0.3s ease-out forwards;
  }
  
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div button {
    margin-bottom: 10px;
  }
  
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div .content {

    background-color: #fff;
    display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    cursor: default;
    width: 100%;
    height: 100%;
  }
  
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div .content .row {
      border: none;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
  }
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div .content .row .icon {
    margin: auto 0.7rem;
  }
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div .content .row .title {
    color: #2b2a2a;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.7px;
  }
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div .content .row .faded_text {
    color: #a6a6a6;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.7px;
    margin-left: 0.5rem;
  }
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div .content .row .perssoneshandler {
    margin: auto 0.7rem auto auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div .content .row .perssoneshandler img:hover {
    opacity: 1;
  }
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div .content .row .perssoneshandler .disabled:hover {
    opacity: 0.92;
  }
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div .content .row .perssoneshandler img {
    opacity: 0.92;
    cursor: pointer;
    margin: auto 0.4rem;
  }
  
  .Voyagedetails .main .container .top_section .right_part .row .input1 .sliding-div .content .row .perssoneshandler .disabled {
    filter: invert(93%) sepia(0%) saturate(0%) hue-rotate(142deg) brightness(85%)
      contrast(83%);
  }






  .Voyagedetails .main .container .top_section .right_part .row{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }



  .Voyagedetails .main .container .top_section .right_part .table{
    height: 85%;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
  }


  .Voyagedetails .main .container .top_section .right_part .table .table_header{
    width: 99%;
    border-radius:4px;
    background: #239EFE;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }


  .Voyagedetails .main .container .top_section .right_part .table .table_header .el p{
    margin-left: 1rem;
    color: #FFF;
font-family: "Poppins";
font-size: 13.264px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 1.326px;
text-transform: uppercase;
  }
  .Voyagedetails .main .container .top_section .right_part .table .table_header .el.w40 {
    width: 40%;
  }


  .Voyagedetails .main .container .top_section .right_part .table .table_header .el.w15 {
    width: 15%;
  }

  .Voyagedetails .main .container .top_section .right_part .table .table_header .el.w30 {
    width: 30%;
  }



.Voyagedetails .main .container .top_section .right_part .table .table_body{

    height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;

}

.Voyagedetails .main .container .top_section .right_part .table .table_body .row{
    margin-top: .5rem;
    border-radius: 4px;
    border: 0.284px solid #239EFE;
    background: #FFF; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 99%;
}

.Voyagedetails .main .container .top_section .right_part .table .table_body .row .el{
    height: fit-content;
}

.Voyagedetails .main .container .top_section .right_part .table .table_body .row .el p{
    margin-left: 1rem;
    color: #000;
    font-family: "Poppins";
    font-size: 15.159px;
    font-style: normal;
    font-weight: 500;
    line-height: 179.5%; /* 27.211px */
    letter-spacing: 0.758px;
  }


.Voyagedetails .main .container .top_section .right_part .table .table_body .row .el button{
    cursor: pointer;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #FF6300;
    background-color: #fff;
}

.Voyagedetails .main .container .top_section .right_part .table .table_body .row .el button p{
    margin: 0;
    padding: 0;
    color: #FF6300;
    text-align: center;
    font-family: "Poppins";
    font-size: 13.264px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.949px; /* 142.857% */
}


.Voyagedetails .main .container .top_section .right_part .table .table_body .row .el.w40 {
    width: 40%;
  }


  .Voyagedetails .main .container .top_section .right_part .table .table_body .row .el.w15 {
    width: 15%;
  }

  .Voyagedetails .main .container .top_section .right_part .table .table_body .row .el.w30 {
    width: 30%;
  }




.Voyagedetails .main .container .top_section .left_part{
    width: 35%;
    height: 100%;

}

.Voyagedetails .main .container .top_section .left_part .main_img{
    border-radius: 4px;
    width: 100%;
    height: 70%;
    object-fit: cover;
}

.Voyagedetails .gallery{
    overflow-x: hidden;
    z-index: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: absolute;
    top: 48%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    margin: 0;
    transform: translate(-50%, -50%); /* This is a shorthand of
                                         translateX(-50%) and translateY(-50%) */
    width: 100%;
    height: 105vh;
    background-color: #FFFFFF9C;

}
.Voyagedetails .gallery button{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 4px;
background: #FF6300;
height: 50px;
padding: 1rem 4rem;
}

.Voyagedetails .gallery button p{
    color: #FAFAFA;
text-align: center;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}

.Voyagedetails .gallery .swiper { 
    width: 80%; 
height: 70%; 
margin-top: 5rem;
margin-bottom: 2rem;}

.Voyagedetails .gallery .swiper .swiper-slide.swiper-slide-active {

    margin: auto;
}
.Voyagedetails .gallery .swiper img{
    width: 100%  ;
    height: 100%;
    object-fit: cover;
}

.weird{
  width: 100%;
  height: 400px;
}


.Voyagedetails .main .container .bottom_section{
  margin-bottom: 13rem;
  margin-top:10rem;

  width: 100%;
  padding-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;

}

.Voyagedetails .main .container .bottom_section .sub_section{
  width: 95%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.Voyagedetails .main .container .bottom_section .sub_section .title{
  margin: 3rem 0 2rem 0;
  color: #000;
font-family: "Poppins";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 22.632px; /* 113.158% */
}


.Voyagedetails .main .container .bottom_section .sub_section .lists{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.Voyagedetails .main .container .bottom_section .sub_section .list{
  margin: 0;
  padding-right: 4rem;

}

.Voyagedetails .main .container .bottom_section .sub_section .list ul{
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.632px; /* 141.447% */
  margin: 0;
}
.Voyagedetails .main .container .bottom_section .sub_section p{
  padding: 0;
  margin: 0%;
  color: #000;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22.632px; /* 141.447% */
}

















/* inputs width controllers */

@media only screen and (max-width: 1400px) {
    .Voyagedetails .main .container .top_section .right_part .row .input1 {
        width: 350px;
      }
    .Voyagedetails .main .container .top_section .right_part .row .duration {
      width: 340px;
    }
    .Voyagedetails .main .container .top_section .right_part .row .input.var1 {
      width: 346px;
    }
    .Voyagedetails .main .container .top_section .right_part .row .input.var2{
      width: 350px;
    }
  
  }
  
  @media only screen and (max-width: 1100px) {
    .Voyagedetails .main .container .top_section .right_part .row .input1 {
        width: 240px;
      }
    .Voyagedetails .main .container .top_section .right_part .row .duration {
      width: 240px;
    }
    .Voyagedetails .main .container .top_section .right_part .row .input.var1 {
      width: 246px;
    }
    .Voyagedetails .main .container .top_section .right_part .row .input.var2{
      width: 250px;
    }
  
  }
  
  @media only screen and (min-width: 1400px) {
    .Voyagedetails .main .container .top_section .right_part .row .input1 {
        width: 450px;
      }
    .Voyagedetails .main .container .top_section .right_part .row .duration {
      width: 440px;
    }
    .Voyagedetails .main .container .top_section .right_part .row .input.var1 {
      width: 446px;
    }
    .Voyagedetails .main .container .top_section .right_part .row .input.var2{
      width:450px;
    }
  
  
  }
  @media only screen and (min-width: 1600px) {
    .Voyagedetails .main .container .top_section .right_part .row .input1 {
        width: 47%;
      }
    .Voyagedetails .main .container .top_section .right_part .row .duration {
      width: 490px;
    }
    .Voyagedetails .main .container .top_section .right_part .row .input.var1 {
        width: 47%;
    }
    .Voyagedetails .main .container .top_section .right_part .row .input.var2{
      width:500px;
    }
  
  
  }