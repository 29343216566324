/* General Styles */
.ticket-details {
  padding: 30px;
  max-width: 1000px;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  background-color: #f5f7fa; /* Light grey background */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-height: 80vh; /* Ensure the page has a minimum height */
}

/* Header Section */
.ticket-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.ticket-header h2 {
  font-size: 24px;
  color: #2c3e50;
  margin: 0;
}

.back-button {
  padding: 10px 20px;
  background-color: #34495e;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #2c3e50;
}

/* Ticket Info Section */
.ticket-info {
  background: white;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ticket-info p {
  margin: 10px 0;
  font-size: 16px;
  color: #34495e;
}

.status-badge {
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.status-badge.open {
  background-color: #ffa800;
  color: white;
}

.status-badge.closed {
  background-color: #4caf50;
  color: white;
}

/* Messages Section */
.messages {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
  min-height: 300px; /* Minimum height for the chat section */
  max-height: 500px; /* Maximum height for the chat section */
  overflow-y: auto; /* Add scrollbar if messages overflow */
  padding: 10px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.message {
  padding: 15px;
  border-radius: 12px;
  max-width: 70%;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.message.user {
  background-color: #4caf50;
  align-self: flex-end;
  color: white;
}

.message.admin {
  background-color: #239efe;
  align-self: flex-start;
  color: white;
}

.message-content p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

.message-content small {
  display: block;
  margin-top: 8px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}

/* Message Input Section */
.message-input {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.message-input textarea {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  resize: none;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-input textarea:focus {
  outline: none;
  border-color: #239efe;
  box-shadow: 0 0 0 2px rgba(35, 158, 254, 0.2);
}

.send-button {
  padding: 12px 24px;
  background-color: #043263;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.send-button:hover {
  background-color: #1a4a8a;
}

/* Responsive Design */
@media (max-width: 768px) {
  .ticket-details {
    padding: 20px;
  }

  .ticket-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .message {
    max-width: 85%;
  }

  .message-input {
    flex-direction: column;
    gap: 10px;
  }

  .message-input textarea {
    width: 100%;
  }

  .message-input button {
    width: 100%;
  }
}