.Carouselcard {
  min-width: 320px;
  max-width: 380px;
  height: 500;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  background-color: #ffffff;
}

.Carouselcard:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

.Carouselcard .upper_part {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 95%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  transition: transform 0.3s ease;
}

.Carouselcard .upper_part::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, rgba(0,0,0,0.6), transparent);
  z-index: 1;
}

.Carouselcard .upper_part .title {
  position: relative;
  z-index: 2;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  margin-bottom: 10px;
}

.Carouselcard .upper_part .offer_tag {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #239EFE;
  border-radius: 20px;
  padding: 5px 15px;
  z-index: 3;
}

.Carouselcard .upper_part .offer_tag p {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
}

.Carouselcard .lower_part {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 250px);
}

.Carouselcard .lower_part .informations {
  width: 100%;
}

.Carouselcard .lower_part .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.Carouselcard .lower_part .titlediv {
  display: flex;
  align-items: center;
}

.Carouselcard .lower_part .titlediv img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #239EFE;
}

.Carouselcard .lower_part .titlediv p {
  color: #239EFE;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.Carouselcard .lower_part .duration {
  color: #6c757d;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.Carouselcard .lower_part .title {
  color: #6c757d;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.Carouselcard .lower_part .black_text {
  color: #131313;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.Carouselcard .lower_part .voyage_card_button {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 8px;
  background-color: #FF7A00;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.Carouselcard .lower_part .voyage_card_button:hover {
  background-color: #FF9A33;
  transform: scale(1.02);
}

/* Tablet Responsive Styles */
@media (max-width: 827px) {
  .Carouselcard {
    min-width: 420px;
    max-width: 320px;
    height: 500;
  }

  .Carouselcard .upper_part {
    height: 220px;
  }

  .Carouselcard .upper_part .title {
    font-size: 14px;
  }

  .Carouselcard .lower_part {
    padding: 15px;
  }
}



@media (max-width: 500px) {
  .Carouselcard {
    min-width: 280px;
    
    height: 500;
  }
}
