.Page_login {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Page_login .main {
    display: flex;
    align-items: row;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    min-height: 910px;
}

.Page_login .main .left_part {
    background-image: url("../../assets/images/images/login.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
    min-height: 910px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Page_login .main .right_part {
    background-color: #fff;
    width: 50%;
    height: 100%;
    min-height: 910px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Page_login .main .right_part .title {
    color: #000;
    font-family: "Poppins";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 50% */
    margin-bottom: 2rem;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 90%;
}

.Page_login .main .right_part form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
}

.Page_login .main .right_part label p {
    color: #4F4F50;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
}

.Page_login .main .right_part label {
    margin-bottom: 2rem;
    width: 70%;
}

.Page_login .main .right_part label input {
    border-radius: 4px;
    border: 1px solid #E0E1E4;
    background: #FFF;
    min-width: 100px;
    width: 100%;
    height: 45px;
    color: #8D8E90;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 1rem;
}

.Page_login .main .right_part label .password {
    width: 100%;
    position: relative;
}

.Page_login .main .right_part label .password img {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 0;
}

.Page_login .main .right_part form button {
    cursor: pointer;
    margin: 2rem 0 3rem 0;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #239EFE;
    border: none;
    width: 45%;
    min-width: 200px;
    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.Page_login .main .error-message {
    color: red;
    margin-top: 0px;
    font-size: 12px;
    position: absolute;
}

.Page_login .main .line {
    width: 40%;
    height: 2px;
    background: #9C9C9C;
    margin-top: 2rem;
}

.Page_login .main .footer {
    margin-top: 4rem;
    color: #4F4F50;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.Page_login .main .footer a {
    cursor: pointer;
    text-decoration: none;
    color: #239EFE;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
}

.login-link {
    margin-top: 20px;
    text-align: center;
  }
  
  .login-link a {
    color: #007BFF; /* Change to your preferred color */
    text-decoration: none; /* Remove the default underline */
    font-weight: bold;
    font-size: 14px;
    transition: color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  /* Hover effect for the anchor link */
  .login-link a:hover {
    color: #0056b3; /* Darker shade on hover */
    text-decoration: underline; /* Add underline on hover */
  }

  
  /* Media query for mobile */
@media (max-width: 768px) {
    .Page_login .main {
        flex-direction: column;
    }

    .Page_login .main .left_part {
        display: none; /* Hide the left part on mobile */
    }

    .Page_login .main .right_part {
        width: 100%;
        padding: 0;
        min-height: 100vh; /* Full viewport height */
    }

    .Page_login .main .right_part .title {
        font-size: 32px;
        margin-bottom: 1.5rem;
        text-align: center;
    }

    .Page_login .main .right_part form {
        width: 80%;
        padding: 0 1rem;
    }

    .Page_login .main .right_part label {
        width: 100%;
    }

    .Page_login .main .right_part form button {
        width: 80%;
        min-width: unset;
    }

    .Page_login .main .line {
        width: 60%;
    }

    .Page_login .main .footer,
    .Page_login .main .login-link {
        font-size: 12px;
    }

    .Page_login .main .login-link a {
        font-size: 14px;
    }
}
