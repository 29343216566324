

.css-1hb7zxy-IndicatorsContainer{
    position: absolute;
    right: 10px;
    top: 5px;
}
.css-b62m3t-container {
    width: 450px;
    transition: width 0.3s ease-in-out; /* Smooth transition for width changes */
}

/* Large screens */
@media only screen and (min-width: 1800px) {
    .css-b62m3t-container {
        width: 550px;
    }
}

/* Medium-large screens */
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
    .css-b62m3t-container {
        width: 500px;
    }
}

/* Medium screens */
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .css-b62m3t-container {
        width: 450px;
    }
}

/* Small-medium screens */
@media only screen and (min-width: 1100px) and (max-width: 1399px) {
    .css-b62m3t-container {
        width: 350px;
    }
}

/* Tablet screens */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
    .css-b62m3t-container {
        width: 300px;
    }
}

/* Mobile screens */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .css-b62m3t-container {
        width: 250px;
    }
}

/* Small mobile screens */
@media only screen and (max-width: 479px) {
    .css-b62m3t-container {
        width: 200px;
    }
}

.css-djdrmy-ValueContainer {
    min-width: 0px !important;
    transition: min-width 0.3s ease-in-out; /* Smooth transition for min-width changes */
}

/* Adjust dropdown indicator position for smaller screens */
.css-1hb7zxy-IndicatorsContainer {
    position: absolute;
    right: 5px; /* Reduce right margin for smaller screens */
    top: 5px;
}

@media only screen and (max-width: 767px) {
    .css-1hb7zxy-IndicatorsContainer {
        right: 3px; /* Further adjust for smaller screens */
        top: 3px;
    }
}
